import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 18 19" {...props}>
      <path
        d="M4.46006 8.73642C3.32528 8.73642 2.25819 8.29811 1.4558 7.50226C0.653407 6.7064 0.211182 5.64769 0.211182 4.52215C0.211182 3.39662 0.653088 2.33822 1.45548 1.54237C2.25819 0.746198 3.32496 0.307892 4.46006 0.307892C5.59515 0.307892 6.66192 0.746198 7.46431 1.54237C8.2667 2.33822 8.70861 3.39662 8.70861 4.52215C8.70861 5.64769 8.2667 6.70608 7.46431 7.50194C6.66192 8.29779 5.59483 8.7361 4.46006 8.7361V8.73642ZM4.46006 0.65784C3.41947 0.65784 2.44115 1.05973 1.70517 1.78971C0.969191 2.51969 0.564004 3.49005 0.564004 4.52215C0.564004 5.55426 0.969191 6.52462 1.70517 7.2546C2.44115 7.98458 3.41947 8.38647 4.46006 8.38647C5.50064 8.38647 6.47897 7.98458 7.21494 7.2546C7.95092 6.52462 8.35611 5.55426 8.35611 4.52215C8.35611 3.49005 7.95092 2.51969 7.21494 1.78971C6.47897 1.05973 5.50064 0.65784 4.46006 0.65784Z"
        fill="url(#paint0_linear_425_2214)"
      />
      <path
        d="M4.46014 0.402862C5.56938 0.402862 6.61252 0.831351 7.39671 1.60947C8.18122 2.38759 8.61323 3.42192 8.61323 4.52212C8.61323 5.62232 8.18122 6.65696 7.39671 7.43476C6.6122 8.21288 5.56938 8.64137 4.46014 8.64137C3.35091 8.64137 2.30777 8.21288 1.52357 7.43476C0.739064 6.65664 0.307056 5.62232 0.307056 4.52212C0.307056 3.42192 0.739064 2.38727 1.52357 1.60947C2.30808 0.831351 3.35091 0.402862 4.46014 0.402862ZM4.46014 8.48112C5.52627 8.48112 6.52886 8.06942 7.28272 7.32138C8.03658 6.57367 8.45198 5.57924 8.45198 4.5218C8.45198 3.46435 8.0369 2.46993 7.28272 1.72221C6.52886 0.974497 5.52627 0.562476 4.46014 0.562476C3.39401 0.562476 2.39142 0.97418 1.63756 1.72221C0.883705 2.46993 0.468301 3.46435 0.468301 4.5218C0.468301 5.57924 0.883386 6.57367 1.63756 7.32138C2.39142 8.0691 3.39401 8.48112 4.46014 8.48112ZM4.46014 0.212845C3.2995 0.212845 2.20878 0.660969 1.38819 1.47488C0.567283 2.28878 0.115479 3.37093 0.115479 4.52212C0.115479 5.6733 0.567283 6.75513 1.38787 7.56904C2.20846 8.38295 3.2995 8.83107 4.45982 8.83107C5.62014 8.83107 6.71118 8.38295 7.53177 7.56904C8.35236 6.75513 8.80416 5.67299 8.80416 4.52212C8.80416 3.37125 8.35236 2.2891 7.53177 1.47519C6.7115 0.660969 5.62046 0.212845 4.46014 0.212845ZM4.46014 8.29111C3.4451 8.29111 2.49072 7.89904 1.77294 7.18711C1.05517 6.47517 0.659878 5.52857 0.659878 4.5218C0.659878 3.51503 1.05517 2.56842 1.77294 1.85649C2.49072 1.14456 3.4451 0.752493 4.46014 0.752493C5.47518 0.752493 6.42956 1.14456 7.14734 1.85649C7.86512 2.56842 8.2604 3.51503 8.2604 4.5218C8.2604 5.52857 7.86512 6.47517 7.14734 7.18711C6.42956 7.89904 5.47518 8.29111 4.46014 8.29111Z"
        fill="url(#paint1_linear_425_2214)"
      />
      <path
        d="M4.46016 8.85105C3.29441 8.85105 2.19827 8.40071 1.37416 7.583C0.549742 6.7653 0.0957031 5.6784 0.0957031 4.52214C0.0957031 3.36589 0.549742 2.27899 1.37416 1.46128C2.19827 0.643578 3.29441 0.193237 4.46016 0.193237C5.62591 0.193237 6.72206 0.643578 7.54616 1.46128C8.37058 2.27899 8.82462 3.36589 8.82462 4.52214C8.82462 5.6784 8.37058 6.76561 7.54616 7.583C6.72174 8.40071 5.62591 8.85105 4.46016 8.85105ZM4.46016 0.772473C3.45055 0.772473 2.50096 1.16264 1.78701 1.87077C1.07307 2.5789 0.679696 3.52044 0.679696 4.52214C0.679696 5.52385 1.07307 6.46539 1.78701 7.17352C2.50096 7.88165 3.45023 8.27182 4.46016 8.27182C5.4701 8.27182 6.41936 7.88165 7.13331 7.17352C7.84726 6.46539 8.24063 5.52385 8.24063 4.52214C8.24063 3.52044 7.84726 2.5789 7.13331 1.87077C6.41936 1.16264 5.4701 0.772473 4.46016 0.772473Z"
        fill="url(#paint2_linear_425_2214)"
      />
      <path
        d="M4.46025 0.288223C5.60045 0.288223 6.67233 0.728746 7.47855 1.5284C8.28478 2.32806 8.72892 3.3912 8.72892 4.52212C8.72892 5.65304 8.28478 6.71619 7.47855 7.51584C6.67233 8.3155 5.60045 8.75602 4.46025 8.75602C3.32004 8.75602 2.24816 8.3155 1.44194 7.51584C0.635719 6.71619 0.191578 5.65304 0.191578 4.52212C0.191578 3.3912 0.635719 2.32806 1.44194 1.5284C2.24816 0.728746 3.32004 0.288223 4.46025 0.288223ZM4.46025 8.36648C5.49573 8.36648 6.46894 7.9665 7.20109 7.24032C7.93323 6.51413 8.3365 5.54885 8.3365 4.5218C8.3365 3.49476 7.93323 2.52947 7.20109 1.80329C6.46894 1.07711 5.49573 0.677124 4.46025 0.677124C3.42477 0.677124 2.45156 1.07711 1.71941 1.80329C0.987264 2.52947 0.583993 3.49476 0.583993 4.5218C0.583993 5.54885 0.987264 6.51413 1.71941 7.24032C2.45156 7.9665 3.42477 8.36648 4.46025 8.36648ZM4.46025 0.0982056C3.26895 0.0982056 2.14886 0.558364 1.30656 1.39381C0.463937 2.22956 0 3.34053 0 4.52212C0 5.70371 0.463937 6.81468 1.30624 7.65012C2.14854 8.48556 3.26863 8.94572 4.45993 8.94572C5.65122 8.94572 6.77131 8.48556 7.61362 7.65012C8.45592 6.81468 8.91986 5.70371 8.91986 4.52212C8.91986 3.34053 8.45592 2.22956 7.61362 1.39412C6.77163 0.558364 5.65154 0.0982056 4.46025 0.0982056ZM4.46025 8.17647C3.47618 8.17647 2.55086 7.79643 1.85479 7.10604C1.15873 6.41564 0.77557 5.49818 0.77557 4.5218C0.77557 3.54543 1.15873 2.62797 1.85479 1.93757C2.55086 1.24718 3.47586 0.867142 4.46025 0.867142C5.44464 0.867142 6.36964 1.24718 7.0657 1.93757C7.76177 2.62797 8.14493 3.54543 8.14493 4.5218C8.14493 5.49818 7.76177 6.41564 7.0657 7.10604C6.36964 7.79643 5.44464 8.17647 4.46025 8.17647Z"
        fill="url(#paint3_linear_425_2214)"
      />
      <path
        d="M4.42465 6.86788C4.19699 6.86788 4.00733 6.72822 3.94092 6.51223C3.66153 5.60363 3.36969 5.31418 2.45395 5.03738C2.23619 4.97151 2.0957 4.78308 2.0957 4.55759C2.0957 4.3321 2.23651 4.14335 2.45427 4.0778C3.37001 3.80069 3.66217 3.51123 3.94124 2.60295C4.00765 2.38696 4.19731 2.2473 4.42497 2.2473C4.65263 2.2473 4.84229 2.38696 4.9087 2.60295C5.18809 3.51155 5.47992 3.80101 6.39598 4.07811C6.61374 4.14367 6.75423 4.3321 6.75423 4.55791C6.75423 4.78371 6.61342 4.97183 6.39598 5.0377C5.47992 5.31449 5.18809 5.60395 4.9087 6.51255C4.84229 6.72854 4.65231 6.8682 4.42497 6.8682L4.42465 6.86788Z"
        fill="url(#paint4_linear_425_2214)"
      />
      <path
        d="M4.42457 2.34199C4.60912 2.34199 4.76302 2.45505 4.81666 2.63019C5.10467 3.56665 5.42332 3.88272 6.36748 4.16838C6.54373 4.22158 6.65804 4.37454 6.65804 4.55728C6.65804 4.74001 6.54405 4.89297 6.36748 4.94618C5.42332 5.23152 5.10467 5.5479 4.81666 6.48405C4.7627 6.65918 4.6088 6.77224 4.42457 6.77224C4.24033 6.77224 4.08611 6.65918 4.03247 6.48405C3.74447 5.54758 3.42581 5.23152 2.48165 4.94618C2.30508 4.89266 2.19109 4.74001 2.19109 4.55728C2.19109 4.37454 2.30508 4.22158 2.48165 4.16838C3.42581 3.88272 3.74447 3.56665 4.03247 2.63019C4.08643 2.45505 4.24033 2.34199 4.42457 2.34199ZM4.42457 2.15198C4.1538 2.15198 3.92806 2.31793 3.84919 2.57477C3.57875 3.45454 3.31278 3.71835 2.42577 3.98659C2.16682 4.0645 1.99951 4.28872 1.99951 4.55728C1.99951 4.82584 2.16682 5.04974 2.42577 5.12796C3.31278 5.3962 3.57875 5.66001 3.84919 6.53979C3.92806 6.79663 4.15412 6.96258 4.42457 6.96258C4.69501 6.96258 4.92107 6.79663 4.99994 6.53979C5.27038 5.66001 5.53636 5.3962 6.42336 5.12828C6.68231 5.05006 6.84962 4.82583 6.84962 4.55759C6.84962 4.28935 6.68231 4.06482 6.42336 3.98691C5.53667 3.71867 5.27038 3.45486 4.99994 2.57508C4.92107 2.31824 4.69501 2.15229 4.42457 2.15229V2.15198Z"
        fill="url(#paint5_linear_425_2214)"
      />
      <path
        d="M13.7731 7.10919C13.6278 7.10919 13.5001 7.01196 13.4621 6.87294L13.1987 5.90226C13.0614 5.39682 12.6805 4.99778 12.1792 4.83468L11.5368 4.62567C11.4043 4.5826 11.3152 4.46067 11.3152 4.32227C11.3152 4.18388 11.4043 4.06195 11.5368 4.01888L12.1792 3.80986C12.6805 3.64676 13.0617 3.24773 13.1987 2.74228L13.4621 1.77161C13.5001 1.63258 13.6278 1.53535 13.7731 1.53535C13.9184 1.53535 14.0461 1.63258 14.0841 1.77161L14.3475 2.74228C14.4848 3.24741 14.8658 3.64676 15.3671 3.80986L16.0095 4.01888C16.142 4.06195 16.2311 4.18388 16.2311 4.32227C16.2311 4.46067 16.142 4.5826 16.0095 4.62567L15.3671 4.83468C14.8658 4.99778 14.4848 5.39682 14.3475 5.90226L14.0841 6.87294C14.0461 7.01196 13.9184 7.10919 13.7731 7.10919ZM12.6211 4.32227C13.161 4.57024 13.5761 5.02375 13.7731 5.58367C13.9698 5.02375 14.3852 4.57024 14.9248 4.32227C14.3852 4.0743 13.9698 3.62079 13.7731 3.06088C13.5764 3.62079 13.161 4.0743 12.6211 4.32227Z"
        fill="url(#paint6_linear_425_2214)"
      />
      <path
        d="M13.7731 1.63036C13.8753 1.63036 13.9647 1.69844 13.9915 1.7963L14.255 2.76697C14.4006 3.30346 14.8051 3.72688 15.337 3.90011L15.9795 4.10913C16.0724 4.13921 16.1353 4.22536 16.1353 4.32226C16.1353 4.41917 16.0727 4.50531 15.9795 4.5354L15.337 4.74442C14.8051 4.91765 14.4006 5.34107 14.255 5.87755L13.9915 6.84823C13.9647 6.94608 13.8753 7.01417 13.7731 7.01417C13.671 7.01417 13.5816 6.94608 13.5547 6.84823L13.2913 5.87755C13.1457 5.34107 12.7412 4.91765 12.2089 4.74442L11.5665 4.5354C11.4736 4.50531 11.411 4.41917 11.411 4.32226C11.411 4.22536 11.4736 4.13921 11.5665 4.10913L12.2089 3.90011C12.7409 3.72688 13.1457 3.30346 13.2913 2.76697L13.5547 1.7963C13.5816 1.69844 13.671 1.63036 13.7731 1.63036ZM13.7731 5.92696L13.8182 5.76069C14.0027 5.08202 14.5126 4.5449 15.1838 4.32195C14.5126 4.09899 14.0027 3.5622 13.8182 2.8832L13.7731 2.71694L13.7281 2.8832C13.5436 3.56188 13.0336 4.09868 12.3625 4.32195C13.034 4.5449 13.5439 5.0817 13.7281 5.76069L13.7731 5.92696ZM13.7731 1.44034C13.5848 1.44034 13.419 1.56638 13.3699 1.74658L13.1064 2.71757C12.9778 3.19198 12.6198 3.56631 12.1495 3.71959L11.5071 3.92861C11.3353 3.98467 11.2197 4.1427 11.2197 4.32226C11.2197 4.50183 11.3353 4.65986 11.5071 4.71592L12.1495 4.92494C12.6202 5.07822 12.9778 5.45255 13.1064 5.92696L13.3699 6.89763C13.419 7.07815 13.5851 7.20419 13.7731 7.20419C13.9612 7.20419 14.1272 7.07815 14.1764 6.89795L14.4398 5.92696C14.5685 5.45255 14.9261 5.07822 15.3968 4.92494L16.0392 4.71592C16.2113 4.65986 16.3269 4.50183 16.3269 4.32226C16.3269 4.1427 16.2113 3.98435 16.0392 3.92861L15.3968 3.71959C14.9264 3.56631 14.5688 3.19198 14.4401 2.71757L14.1767 1.7469C14.1276 1.56638 13.9615 1.44034 13.7735 1.44034H13.7731ZM12.8303 4.32195C13.2421 4.08981 13.5713 3.73765 13.7731 3.31169C13.9749 3.73765 14.3041 4.09013 14.7157 4.32195C14.3038 4.55377 13.9749 4.90625 13.7731 5.33221C13.5713 4.90625 13.2421 4.55377 12.8303 4.32195Z"
        fill="url(#paint7_linear_425_2214)"
      />
      <path
        d="M13.5398 17.8886C12.405 17.8886 11.3379 17.4503 10.5356 16.6544C9.73317 15.8586 9.29126 14.8002 9.29126 13.6746C9.29126 12.5491 9.73317 11.4907 10.5356 10.6948C11.3379 9.89898 12.405 9.46036 13.5398 9.46036C14.6746 9.46036 15.7417 9.89866 16.5441 10.6948C17.3465 11.4907 17.7884 12.5491 17.7884 13.6746C17.7884 14.8002 17.3465 15.8586 16.5441 16.6544C15.7417 17.4503 14.6746 17.8886 13.5398 17.8886ZM13.5398 9.81031C12.4992 9.81031 11.5209 10.2122 10.7849 10.9422C10.0489 11.6722 9.64376 12.6425 9.64376 13.6746C9.64376 14.7067 10.0489 15.6771 10.7849 16.4071C11.5209 17.137 12.4992 17.5389 13.5398 17.5389C14.5804 17.5389 15.5587 17.137 16.2947 16.4071C17.0307 15.6771 17.4359 14.7067 17.4359 13.6746C17.4359 12.6425 17.0307 11.6722 16.2947 10.9422C15.5587 10.2122 14.5804 9.81031 13.5398 9.81031Z"
        fill="url(#paint8_linear_425_2214)"
      />
      <path
        d="M13.5399 9.55533C14.6491 9.55533 15.6923 9.98382 16.4765 10.7619C17.261 11.5401 17.693 12.5744 17.693 13.6746C17.693 14.7748 17.261 15.8094 16.4765 16.5872C15.692 17.3653 14.6491 17.7938 13.5399 17.7938C12.4307 17.7938 11.3875 17.3653 10.6033 16.5872C9.81882 15.8091 9.38681 14.7748 9.38681 13.6746C9.38681 12.5744 9.81882 11.5397 10.6033 10.7619C11.3878 9.98382 12.4307 9.55533 13.5399 9.55533ZM13.5399 17.6336C14.606 17.6336 15.6086 17.2219 16.3625 16.4739C17.1163 15.7261 17.5317 14.7317 17.5317 13.6743C17.5317 12.6168 17.1167 11.6224 16.3625 10.8747C15.6086 10.127 14.606 9.71494 13.5399 9.71494C12.4738 9.71494 11.4712 10.1266 10.7173 10.8747C9.96346 11.6224 9.54806 12.6168 9.54806 13.6743C9.54806 14.7317 9.96314 15.7261 10.7173 16.4739C11.4712 17.2216 12.4738 17.6336 13.5399 17.6336ZM13.5399 9.36531C12.3796 9.36531 11.2885 9.81343 10.468 10.6273C9.64736 11.4412 9.19556 12.5234 9.19556 13.6743C9.19556 14.8251 9.64736 15.9073 10.468 16.7212C11.2885 17.5351 12.3796 17.9832 13.5399 17.9832C14.7002 17.9832 15.7913 17.5351 16.6118 16.7212C17.4324 15.9073 17.8842 14.8251 17.8842 13.6743C17.8842 12.5234 17.4324 11.4412 16.6118 10.6273C15.7913 9.81343 14.7002 9.36531 13.5399 9.36531ZM13.5399 17.4436C12.5249 17.4436 11.5705 17.0515 10.8527 16.3396C10.1349 15.6276 9.73964 14.681 9.73964 13.6743C9.73964 12.6675 10.1349 11.7209 10.8527 11.009C11.5705 10.297 12.5249 9.90496 13.5399 9.90496C14.5549 9.90496 15.5093 10.297 16.2271 11.009C16.9449 11.7209 17.3402 12.6675 17.3402 13.6743C17.3402 14.681 16.9449 15.6276 16.2271 16.3396C15.5093 17.0515 14.5549 17.4436 13.5399 17.4436Z"
        fill="url(#paint9_linear_425_2214)"
      />
      <path
        d="M13.54 18.0035C12.3742 18.0035 11.2781 17.5532 10.454 16.7355C9.62958 15.9178 9.17554 14.8309 9.17554 13.6746C9.17554 12.5184 9.62958 11.4311 10.454 10.6138C11.2784 9.79604 12.3742 9.3457 13.54 9.3457C14.7057 9.3457 15.8019 9.79604 16.626 10.6138C17.4504 11.4315 17.9045 12.5184 17.9045 13.6746C17.9045 14.8309 17.4504 15.9181 16.626 16.7355C15.8016 17.5532 14.7057 18.0035 13.54 18.0035ZM13.54 9.92494C12.5304 9.92494 11.5808 10.3151 10.8668 11.0232C10.1529 11.7314 9.75953 12.6729 9.75953 13.6746C9.75953 14.6763 10.1529 15.6179 10.8668 16.326C11.5808 17.0341 12.5301 17.4243 13.54 17.4243C14.5499 17.4243 15.4992 17.0341 16.2131 16.326C16.9271 15.6179 17.3205 14.6763 17.3205 13.6746C17.3205 12.6729 16.9271 11.7314 16.2131 11.0232C15.4992 10.3151 14.5499 9.92494 13.54 9.92494Z"
        fill="url(#paint10_linear_425_2214)"
      />
      <path
        d="M13.54 9.44069C14.6802 9.44069 15.7521 9.88121 16.5583 10.6809C17.3645 11.4805 17.8087 12.5437 17.8087 13.6746C17.8087 14.8055 17.3645 15.8686 16.5583 16.6683C15.7521 17.468 14.6802 17.9085 13.54 17.9085C12.3998 17.9085 11.3279 17.468 10.5217 16.6683C9.71548 15.8686 9.27134 14.8055 9.27134 13.6746C9.27134 12.5437 9.71548 11.4805 10.5217 10.6809C11.3279 9.88121 12.3998 9.44069 13.54 9.44069ZM13.54 17.5189C14.5755 17.5189 15.5487 17.119 16.2808 16.3928C17.013 15.6666 17.4163 14.7013 17.4163 13.6743C17.4163 12.6472 17.013 11.6819 16.2808 10.9558C15.5487 10.2296 14.5755 9.82959 13.54 9.82959C12.5045 9.82959 11.5313 10.2296 10.7992 10.9558C10.067 11.6819 9.66375 12.6472 9.66375 13.6743C9.66375 14.7013 10.067 15.6666 10.7992 16.3928C11.5313 17.119 12.5045 17.5189 13.54 17.5189ZM13.54 9.25067C12.3487 9.25067 11.2286 9.71083 10.3863 10.5463C9.54402 11.3817 9.08008 12.4927 9.08008 13.6743C9.08008 14.8559 9.54402 15.9668 10.3863 16.8023C11.2286 17.6377 12.3487 18.0979 13.54 18.0979C14.7313 18.0979 15.8514 17.6377 16.6937 16.8023C17.536 15.9668 17.9999 14.8559 17.9999 13.6743C17.9999 12.4927 17.536 11.3817 16.6937 10.5463C15.8514 9.71083 14.7313 9.25067 13.54 9.25067ZM13.54 17.3289C12.5559 17.3289 11.6306 16.9489 10.9345 16.2585C10.2385 15.5681 9.85533 14.6506 9.85533 13.6743C9.85533 12.6979 10.2385 11.7804 10.9345 11.09C11.6306 10.3996 12.5556 10.0196 13.54 10.0196C14.5244 10.0196 15.4494 10.3996 16.1455 11.09C16.8415 11.7804 17.2247 12.6979 17.2247 13.6743C17.2247 14.6506 16.8415 15.5681 16.1455 16.2585C15.4494 16.9489 14.5244 17.3289 13.54 17.3289Z"
        fill="url(#paint11_linear_425_2214)"
      />
      <path
        d="M13.5758 16.0203C13.3481 16.0203 13.1585 15.8807 13.092 15.6647C12.8127 14.7561 12.5208 14.4666 11.6051 14.1898C11.3873 14.124 11.2468 13.9355 11.2468 13.71C11.2468 13.4845 11.3876 13.2958 11.6051 13.2302C12.5211 12.9531 12.813 12.6637 13.092 11.7551C13.1585 11.5391 13.3484 11.3994 13.5758 11.3994C13.8031 11.3994 13.9931 11.5391 14.0595 11.7551C14.3389 12.6637 14.6307 12.9531 15.5468 13.2299C15.7645 13.2955 15.905 13.4839 15.905 13.7097C15.905 13.9355 15.7642 14.1236 15.5468 14.1895C14.6307 14.4663 14.3389 14.7558 14.0595 15.6644C13.9931 15.8803 13.8034 16.02 13.5758 16.02V16.0203Z"
        fill="url(#paint12_linear_425_2214)"
      />
      <path
        d="M13.5757 11.4945C13.7602 11.4945 13.9141 11.6075 13.9678 11.7827C14.2558 12.7191 14.5744 13.0352 15.5186 13.3208C15.6952 13.374 15.8092 13.5267 15.8092 13.7097C15.8092 13.8928 15.6952 14.0454 15.5186 14.0986C14.5744 14.384 14.2558 14.7004 13.9678 15.6365C13.9138 15.8116 13.7599 15.9247 13.5757 15.9247C13.3915 15.9247 13.2372 15.8116 13.1836 15.6365C12.8956 14.7 12.5769 14.384 11.6328 14.0986C11.4565 14.0451 11.3422 13.8925 11.3422 13.7097C11.3422 13.527 11.4562 13.374 11.6328 13.3208C12.5769 13.0352 12.8956 12.7191 13.1836 11.7827C13.2376 11.6075 13.3915 11.4945 13.5757 11.4945ZM13.5757 11.3044C13.3049 11.3044 13.0792 11.4704 13.0003 11.7272C12.7299 12.607 12.4639 12.8708 11.5769 13.1391C11.3179 13.217 11.1506 13.4412 11.1506 13.7097C11.1506 13.9783 11.3179 14.2022 11.5769 14.2804C12.4639 14.5487 12.7299 14.8125 13.0003 15.6923C13.0792 15.9491 13.3052 16.115 13.5757 16.115C13.8461 16.115 14.0722 15.9491 14.1511 15.6923C14.4215 14.8125 14.6875 14.5487 15.5745 14.2807C15.8334 14.2025 16.0007 13.9783 16.0007 13.7101C16.0007 13.4418 15.8334 13.2173 15.5745 13.1394C14.6878 12.8711 14.4218 12.6073 14.1514 11.7275C14.0725 11.4707 13.8465 11.3048 13.576 11.3048L13.5757 11.3044Z"
        fill="url(#paint13_linear_425_2214)"
      />
      <path
        d="M4.22698 16.2617C4.08202 16.2617 3.95399 16.1644 3.91599 16.0254L3.65257 15.0547C3.51527 14.5496 3.13435 14.1502 2.63306 13.9872L1.99063 13.7781C1.85813 13.7351 1.76904 13.6131 1.76904 13.4747C1.76904 13.3363 1.85813 13.2144 1.99063 13.1713L2.63306 12.9623C3.13435 12.7992 3.51527 12.4002 3.65257 11.8947L3.91599 10.9241C3.95399 10.785 4.0817 10.6878 4.22698 10.6878C4.37226 10.6878 4.49998 10.785 4.53798 10.9241L4.8014 11.8947C4.9387 12.4002 5.31962 12.7992 5.82091 12.9623L6.46333 13.1713C6.59584 13.2144 6.68493 13.3363 6.68493 13.4747C6.68493 13.6131 6.59584 13.7351 6.46333 13.7781L5.82091 13.9872C5.31962 14.1502 4.93838 14.5493 4.8014 15.0547L4.53798 16.0254C4.49998 16.1644 4.37226 16.2617 4.22698 16.2617ZM3.07528 13.4747C3.61489 13.7227 4.0303 14.1762 4.22698 14.7361C4.42399 14.1762 4.83908 13.7227 5.379 13.4747C4.83908 13.2264 4.42367 12.7729 4.22698 12.2133C4.0303 12.7733 3.61489 13.2268 3.07528 13.4747Z"
        fill="url(#paint14_linear_425_2214)"
      />
      <path
        d="M4.22707 10.7828C4.32925 10.7828 4.41865 10.8509 4.44547 10.9488L4.70889 11.9195C4.85449 12.4559 5.25904 12.8794 5.7913 13.0526L6.43373 13.2616C6.52664 13.2917 6.58922 13.3778 6.58922 13.4747C6.58922 13.5717 6.52664 13.6578 6.43373 13.6879L5.7913 13.8969C5.25936 14.0701 4.85449 14.4936 4.70889 15.03L4.44547 16.0007C4.41865 16.0986 4.32925 16.1667 4.22707 16.1667C4.1249 16.1667 4.03549 16.0986 4.00867 16.0007L3.74525 15.03C3.59965 14.4936 3.19511 14.0701 2.66316 13.8969L2.02073 13.6879C1.92782 13.6578 1.86492 13.5717 1.86492 13.4747C1.86492 13.3778 1.9275 13.2917 2.02073 13.2616L2.66316 13.0526C3.19511 12.8794 3.59965 12.4559 3.74525 11.9195L4.00867 10.9488C4.03549 10.8509 4.1249 10.7828 4.22707 10.7828ZM4.22707 15.0794L4.27209 14.9132C4.45664 14.2345 4.96656 13.6977 5.63772 13.4744C4.96624 13.2515 4.45633 12.7147 4.27209 12.0357L4.22707 11.8694L4.18205 12.0357C3.9975 12.7144 3.48758 13.2515 2.81642 13.4744C3.48758 13.6974 3.9975 14.2342 4.18205 14.9132L4.22707 15.0794ZM4.22707 10.5928C4.03869 10.5928 3.87297 10.7189 3.8238 10.8991L3.56038 11.8697C3.4317 12.3441 3.07409 12.7185 2.60345 12.8718L1.96103 13.0808C1.78893 13.1368 1.67334 13.2949 1.67334 13.4744C1.67334 13.654 1.78893 13.8123 1.96103 13.8681L2.60345 14.0771C3.07377 14.2304 3.43138 14.6047 3.56006 15.0791L3.82348 16.0498C3.87265 16.2303 4.03869 16.3564 4.22675 16.3564C4.41482 16.3564 4.58085 16.2303 4.63002 16.0501L4.89344 15.0791C5.02212 14.6047 5.38005 14.2304 5.85037 14.0771L6.4928 13.8681C6.66458 13.812 6.78016 13.654 6.78016 13.4744C6.78016 13.2949 6.66458 13.1368 6.4928 13.0808L5.85037 12.8718C5.37973 12.7185 5.02212 12.3441 4.89344 11.8697L4.63002 10.8991C4.58085 10.7185 4.41482 10.5925 4.22675 10.5925L4.22707 10.5928ZM3.28451 13.4747C3.6964 13.2429 4.02528 12.8904 4.22707 12.4645C4.42887 12.8904 4.75806 13.2429 5.16995 13.4747C4.75806 13.7069 4.42887 14.059 4.22707 14.485C4.02528 14.059 3.69608 13.7066 3.28451 13.4747Z"
        fill="url(#paint15_linear_425_2214)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_425_2214"
          x1="4.46144"
          y1="-1.12926"
          x2="4.46144"
          y2="13.2792"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A088F2" />
          <stop offset="1" stop-color="#524FFB" />
          <stop offset="1.0001" stop-color="#524FFB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_425_2214"
          x1="4.4614"
          y1="-1.25666"
          x2="4.4614"
          y2="13.4761"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A088F2" />
          <stop offset="1" stop-color="#524FFB" />
          <stop offset="1.0001" stop-color="#524FFB" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_425_2214"
          x1="4.46175"
          y1="-1.28301"
          x2="4.46175"
          y2="13.5174"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A088F2" />
          <stop offset="1" stop-color="#524FFB" />
          <stop offset="1.0001" stop-color="#524FFB" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_425_2214"
          x1="4.46155"
          y1="-1.41039"
          x2="4.46155"
          y2="13.7143"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A088F2" />
          <stop offset="1" stop-color="#524FFB" />
          <stop offset="1.0001" stop-color="#524FFB" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_425_2214"
          x1="4.42581"
          y1="1.45939"
          x2="4.42581"
          y2="9.35876"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A088F2" />
          <stop offset="1" stop-color="#524FFB" />
          <stop offset="1.0001" stop-color="#524FFB" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_425_2214"
          x1="4.42545"
          y1="1.33172"
          x2="4.42545"
          y2="9.55539"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A088F2" />
          <stop offset="1" stop-color="#524FFB" />
          <stop offset="1.0001" stop-color="#524FFB" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_425_2214"
          x1="13.774"
          y1="0.584955"
          x2="13.774"
          y2="10.1134"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A088F2" />
          <stop offset="1" stop-color="#524FFB" />
          <stop offset="1.0001" stop-color="#524FFB" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_425_2214"
          x1="13.7742"
          y1="0.457538"
          x2="13.7742"
          y2="10.3108"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A088F2" />
          <stop offset="1" stop-color="#524FFB" />
          <stop offset="1.0001" stop-color="#524FFB" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_425_2214"
          x1="13.5414"
          y1="8.02326"
          x2="13.5414"
          y2="22.4312"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A088F2" />
          <stop offset="1" stop-color="#524FFB" />
          <stop offset="1.0001" stop-color="#524FFB" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_425_2214"
          x1="13.5415"
          y1="7.89586"
          x2="13.5415"
          y2="22.6281"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A088F2" />
          <stop offset="1" stop-color="#524FFB" />
          <stop offset="1.0001" stop-color="#524FFB" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_425_2214"
          x1="13.5416"
          y1="7.86945"
          x2="13.5416"
          y2="22.6699"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A088F2" />
          <stop offset="1" stop-color="#524FFB" />
          <stop offset="1.0001" stop-color="#524FFB" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_425_2214"
          x1="13.5416"
          y1="7.74213"
          x2="13.5416"
          y2="22.8663"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A088F2" />
          <stop offset="1" stop-color="#524FFB" />
          <stop offset="1.0001" stop-color="#524FFB" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_425_2214"
          x1="13.5768"
          y1="10.6115"
          x2="13.5768"
          y2="18.5109"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A088F2" />
          <stop offset="1" stop-color="#524FFB" />
          <stop offset="1.0001" stop-color="#524FFB" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_425_2214"
          x1="13.5766"
          y1="10.4842"
          x2="13.5766"
          y2="18.7079"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A088F2" />
          <stop offset="1" stop-color="#524FFB" />
          <stop offset="1.0001" stop-color="#524FFB" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_425_2214"
          x1="4.22788"
          y1="9.73742"
          x2="4.22788"
          y2="19.2658"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A088F2" />
          <stop offset="1" stop-color="#524FFB" />
          <stop offset="1.0001" stop-color="#524FFB" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_425_2214"
          x1="4.22768"
          y1="9.6097"
          x2="4.22768"
          y2="19.4629"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A088F2" />
          <stop offset="1" stop-color="#524FFB" />
          <stop offset="1.0001" stop-color="#524FFB" />
        </linearGradient>
      </defs>
    </Svg>
  );
};
export default Icon;
