import { ChainId, ERC20Token, WETH9 } from '@zinuswap/sdk'
import { USDC, USDT, WBTC_ETH, ZADI_ETHEREUM } from '@zinuswap/tokens'

export const ethereumTokens = {
  zadi: ZADI_ETHEREUM,
  weth: WETH9[ChainId.ETHEREUM],
  usdt: USDT[ChainId.ETHEREUM],
  usdc: USDC[ChainId.ETHEREUM],
  wbtc: WBTC_ETH,
  syrup: new ERC20Token(
    ChainId.ETHEREUM,
    '0x9C40aa4514Bcd52789Ab01fdEB60DE7114d112B3',
    18,
    'SYRUP',
    'SyrupBar Token',
    '',
  ),
}
