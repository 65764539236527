import { useMemo } from "react";
import { useRouter } from "next/router";
import {
  Menu as UikitMenu,
  NextLinkFromReactRouter,
} from "@zinuswap/uikit";
import { useTranslation, languageList } from "@zinuswap/localization";
import { NetworkSwitcher } from "components/NetworkSwitcher";
import useTheme from "hooks/useTheme";
import { useZinuUsdPrice,useNetworkNativeBusdPrice } from "hooks/useBUSDPrice";
import UserMenu from "./UserMenu";
import { useMenuItems } from "./hooks/useMenuItems";
import { getActiveMenuItem, getActiveSubMenuItem } from "./utils";
import { footerLinks } from "./config/footerConfig";

const Menu = (props) => {
  const { isDark, setTheme } = useTheme();
  const zinuPriceUsd = useZinuUsdPrice();
 
  const { currentLanguage, setLanguage, t } = useTranslation();
  const { pathname } = useRouter();
  

  const netzPriceUsd = useNetworkNativeBusdPrice() ;


  const menuItems = useMenuItems();
  const activeMenuItem = getActiveMenuItem({
    menuConfig: menuItems,
    pathname,
  });
  const activeSubMenuItem = getActiveSubMenuItem({
    menuItem: activeMenuItem,
    pathname,
  });

  const toggleTheme = useMemo(() => {
    return () => setTheme(isDark ? "light" : "dark");
  }, [setTheme, isDark]);

  const getFooterLinks = useMemo(() => {
    return footerLinks(t);
  }, [t]);

  return (
    <>
      <UikitMenu
        linkComponent={(linkProps) => {
          return (
            <NextLinkFromReactRouter
              to={linkProps.href}
              {...linkProps}
              prefetch={false}
            />
          );
        }}
        rightSide={
          <>
            {/* <GlobalSettings mode={SettingsMode.GLOBAL} /> */}
            <NetworkSwitcher />
            <UserMenu />
          </>
        }
        banner={null}
        isDark={isDark}
        toggleTheme={toggleTheme}
        currentLang={currentLanguage.code}
        langs={languageList}
        setLang={setLanguage}
        
        zinuPriceUsd={netzPriceUsd}

        links={menuItems}
        subLinks={
          activeMenuItem?.hideSubNav || activeSubMenuItem?.hideSubNav
            ? []
            : activeMenuItem?.items
        }
        footerLinks={getFooterLinks}
        activeItem={activeMenuItem?.href}
        activeSubItem={activeSubMenuItem?.href}
        buyZinuLabel={t("Buy ZADI")}
        {...props}
      />
    </>
  );
};

export default Menu;
