import { multicallAddresses } from '@zinuswap/multicall'

export default {

  masterChef: {
    8453: '0xbe2CF34842F6110bf89F601b7aE876Fb1F203A16',
    9768:'0x9C40aa4514Bcd52789Ab01fdEB60DE7114d112B3', // SOLVED-SD
    2016:'',
    97: '0x1F8ffa3F84a4F13f9e5bA960787B01E2E57e5777', // SOLVED-SD
    56: '0x13884AF5554230D09caC64fB21a7426AC6Ba735B', // TODO-SD
    1: '0x81829eb6bf1344043a019161b20714795196b904', // SOLVED-SD
    5: '0xD349c5b4Ce55294e1104d68A47E0F63972de4D64', // SOLVED-SD

  },

  masterChefV1: {
    8453:'',
    9768:'',
    97: '', // SOLVED-SD
    56: '', 
    1: '', // SOLVED-SD
    5: '', // SOLVED-SD
  },
  sousChef: {
    97: '',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  multiCall: multicallAddresses,
  zinuVault: {
    8453: '',
    9768:"0x1F83F8088Dd52a6e1a72F5ABE3D3CB709501B68E",
    56: '0x1F83F8088Dd52a6e1a72F5ABE3D3CB709501B68E', // TODO-SD
    97: '0x2A481cDD59B43e325C58A5912C4Fba144a86956E', // SOLVED-SD
    1: '0xA39391aaf379c9791755ad827d9F583d6AED1460', // SOLVED-SD
    5: '0xa4473cEc2eC6b02F2bCf1B7658d54aB3d907C302', // SOLVED-SD
  },
  zinuFlexibleSideVault: {
    8453 : '',
    9768:'',
    56: '',
    97: '', // SOLVED-SD
    1: '', // SOLVED-SD
    5: '', // SOLVED-SD
  },
  // TODO: multi
  zap: {
    56: '',
    97: '',
  },
  bZinuFarmBooster: {
    56: '',
    97: '',
  },
  bZinuFarmBoosterProxyFactory: {
    56: '',
    97: '',
  },
}
