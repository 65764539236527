import memoize from 'lodash/memoize'
import { ContextApi } from '@zinuswap/localization'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'ZodiacSwap',
  description:
    'The most popular AMM on BSC by user count! Earn ZADI through yield farming, then stake it in Syrup Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by ZodiacSwap) and more, on a platform you can trust.',
  image: '',
}

interface PathList {
  paths: { [path: string]: { title: string; basePath?: boolean; description?: string } }
  defaultTitleSuffix: string
}

const getPathList = (t: ContextApi['t']): PathList => {
  return {
    paths: {
      '/': { title: t('Home') },
      '/swap': { basePath: true, title: t('Exchange') },
      '/add': { basePath: true, title: t('Add Liquidity') },
      '/remove': { basePath: true, title: t('Remove Liquidity') },
      '/liquidity': { title: t('Liquidity') },
      '/find': { title: t('Import Pool') },
      '/farms': { title: t('Farms') },
      '/staking': { title: t('Staking') },
      '/scanner': { title: t('Scanner') },
      '/info': { title: t('Overview'), description: 'View statistics for ZodiacSwap exchanges.' },
      '/info/pairs': { title: t('Pairs'), description: 'View statistics for ZodiacSwap exchanges.' },
      '/info/tokens': { title: t('Tokens'), description: 'View statistics for ZodiacSwap exchanges.' },
    },
    defaultTitleSuffix: t('ZodiacSwap'),
  }
}

export const getCustomMeta = memoize(
  (path: string, t: ContextApi['t'], _: string): PageMeta => {
    const pathList = getPathList(t)
    const pathMetadata =
      pathList.paths[path] ??
      pathList.paths[Object.entries(pathList.paths).find(([url, data]) => data.basePath && path.startsWith(url))?.[0]]

    if (pathMetadata) {
      return {
        title: `${pathMetadata.title} | ${t(pathList.defaultTitleSuffix)}`,
        ...(pathMetadata.description && { description: pathMetadata.description }),
      }
    }
    return null
  },
  (path, t, locale) => `${path}#${locale}`,
)
