import { Pool } from '@zinuswap/uikit'
import { SerializedWrappedToken } from '@zinuswap/token-lists'
import { goerliTestnetTokens } from '@zinuswap/tokens'
import { PoolCategory } from '../types'


export const livePools: Pool.SerializedPoolConfig<SerializedWrappedToken>[] = [
  {
    sousId: 3000,
    stakingToken: goerliTestnetTokens.zadi,
    earningToken: goerliTestnetTokens.zadi,
    contractAddress: '0xD349c5b4Ce55294e1104d68A47E0F63972de4D64', // SOLVED-SD,
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '10',
    isFinished: false,
  },
  {
    sousId: 3001,
    stakingToken: goerliTestnetTokens.zadi,
    earningToken: goerliTestnetTokens.weth,
    contractAddress: '0xeaBf7C158bdeE67e4E2f52D8d5DaAA66D2ba4578', // SOLVED-SD
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.00001',
    version: 3,
    depositFeeBP: 4,
  },
].map((p) => ({
  ...p,
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}))

// known finished pools
const finishedPools = [].map((p) => ({
  ...p,
  isFinished: true,
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}))

export default [...livePools, ...finishedPools] as Pool.SerializedPoolConfig<SerializedWrappedToken>[]
