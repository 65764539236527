import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 18 19" {...props}>
      <path
        opacity="0.55"
        d="M15.4527 7.02113H12.2396C11.5985 7.02113 11.0769 6.49955 11.0769 5.85848V2.64531C11.0769 2.00425 11.5985 1.48267 12.2396 1.48267H15.4527C16.0938 1.48267 16.6154 2.00425 16.6154 2.64531V5.85848C16.6154 6.49955 16.0938 7.02113 15.4527 7.02113ZM12.2396 2.05467C11.9139 2.05467 11.6489 2.3197 11.6489 2.64531V5.85848C11.6489 6.1841 11.9139 6.44913 12.2396 6.44913H15.4527C15.7783 6.44913 16.0434 6.1841 16.0434 5.85848V2.64531C16.0434 2.3197 15.7783 2.05467 15.4527 2.05467H12.2396Z"
        fill="url(#paint0_linear_425_2189)"
      />
      <path
        d="M6.89777 8.82855H1.83273C0.822189 8.82855 0 8.00637 0 6.99583V1.93078C0 0.920242 0.822189 0.098053 1.83273 0.098053H6.89777C7.90831 0.098053 8.7305 0.920242 8.7305 1.93078V6.99583C8.7305 8.00637 7.90831 8.82855 6.89777 8.82855ZM1.83273 0.999723C1.31944 0.999723 0.90167 1.4175 0.90167 1.93078V6.99583C0.90167 7.50911 1.31944 7.92688 1.83273 7.92688H6.89777C7.41106 7.92688 7.82883 7.50911 7.82883 6.99583V1.93078C7.82883 1.4175 7.41106 0.999723 6.89777 0.999723H1.83273Z"
        fill="url(#paint1_linear_425_2189)"
      />
      <path
        d="M16.1673 18.0981H11.1023C10.0917 18.0981 9.26953 17.2759 9.26953 16.2653V11.2003C9.26953 10.1897 10.0917 9.36755 11.1023 9.36755H16.1673C17.1778 9.36755 18 10.1897 18 11.2003V16.2653C18 17.2759 17.1778 18.0981 16.1673 18.0981ZM11.1023 10.2692C10.589 10.2692 10.1712 10.687 10.1712 11.2003V16.2653C10.1712 16.7786 10.589 17.1964 11.1023 17.1964H16.1673C16.6806 17.1964 17.0984 16.7786 17.0984 16.2653V11.2003C17.0984 10.687 16.6806 10.2692 16.1673 10.2692H11.1023Z"
        fill="url(#paint2_linear_425_2189)"
      />
      <path
        opacity="0.75"
        d="M5.76034 16.7134H2.54717C1.9061 16.7134 1.38452 16.1919 1.38452 15.5508V12.3376C1.38452 11.6966 1.9061 11.175 2.54717 11.175H5.76034C6.4014 11.175 6.92298 11.6966 6.92298 12.3376V15.5508C6.92298 16.1919 6.4014 16.7134 5.76034 16.7134ZM2.54717 11.747C2.22155 11.747 1.95652 12.012 1.95652 12.3376V15.5508C1.95652 15.8764 2.22155 16.1414 2.54717 16.1414H5.76034C6.08595 16.1414 6.35098 15.8764 6.35098 15.5508V12.3376C6.35098 12.012 6.08595 11.747 5.76034 11.747H2.54717Z"
        fill="url(#paint3_linear_425_2189)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_425_2189"
          x1="13.8471"
          y1="0.538298"
          x2="13.8471"
          y2="10.0062"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A088F2" />
          <stop offset="1" stop-color="#524FFB" />
          <stop offset="1.0001" stop-color="#524FFB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_425_2189"
          x1="4.36684"
          y1="-1.39059"
          x2="4.36684"
          y2="13.5341"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A088F2" />
          <stop offset="1" stop-color="#524FFB" />
          <stop offset="1.0001" stop-color="#524FFB" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_425_2189"
          x1="13.6364"
          y1="7.87891"
          x2="13.6364"
          y2="22.8036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A088F2" />
          <stop offset="1" stop-color="#524FFB" />
          <stop offset="1.0001" stop-color="#524FFB" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_425_2189"
          x1="4.15476"
          y1="10.2306"
          x2="4.15476"
          y2="19.6986"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A088F2" />
          <stop offset="1" stop-color="#524FFB" />
          <stop offset="1.0001" stop-color="#524FFB" />
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default Icon;
