/* eslint-disable @typescript-eslint/no-explicit-any */

// @ts-nocheck

import React, { createElement, memo, useState } from "react";
import { Flex } from "../Box";
import isTouchDevice from "../../util/isTouchDevice";
import DropdownMenu from "../DropdownMenu/DropdownMenu";
import MenuItem from "../MenuItem/MenuItem";
import { MenuItemsProps } from "./types";
import { FaAngleDown, FaAngleUp } from "react-icons/fa"; // Import icons for collapse and expand
import styled from "styled-components";

const MenuContainer = styled(MenuItem)<{ isMobile?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${({ isMobile }) => (!isMobile ? "pointer" : "default")};
  &:hover {
    background: ${({ isMobile }) => (!isMobile ? "#1f1f1f" : "transparent")} !important;
  }
`;

const VMenuItems: React.FC<React.PropsWithChildren<MenuItemsProps>> = ({
  items = [],
  activeItem,
  activeSubItem,
  isMobile,
  ...props
}) => {
  const [openSubMenu, setOpenSubMenu] = useState<string | null>(null);

  const handleSubMenuClick = (href: string) => {
    setOpenSubMenu(openSubMenu === href ? null : href);
  };

  return (
    <Flex {...props} flexDirection="column" zIndex="10" style={{ position: "relative" }}>
      {items.map(({ label, items: menuItems = [], href, icon, disabled,isExternal }) => {
        const statusColor = menuItems?.find((menuItem) => menuItem.status !== undefined)?.status?.color;
        const isActive = activeItem === href;
        const isSubMenuOpen = openSubMenu === href;
        const linkProps = isTouchDevice() && menuItems && menuItems.length > 0 ? {} : { href };
        const Icon = icon;

        return (
          <div key={`${label}#${href}`}>
            <MenuContainer
              onClick={() => handleSubMenuClick(href)}
              isActive={isActive}
              statusColor={statusColor}
              isDisabled={disabled}
              className="menu-header"
              isMobile
            >
              <div
                style={{
                  color: "#F9F9F9BF",
                }}
              >
                {label}
              </div>
              {/* Render collapse/expand icon based on submenu visibility */}
              <span>
                <FaAngleDown style={{ color: "#F9F9F9BF" }} />
              </span>
            </MenuContainer>

            {menuItems && menuItems.length > 0 && (!isMobile || isSubMenuOpen) && (
              <Flex flexDirection="column" borderTop="1px solid #A088F2B3" marginTop="5px">
                {menuItems.map(({ label: subLabel, href: subHref, icon: subIcon, disabled: subDisabled,isExternal }) => (
                  <MenuItem
                    key={`${subLabel}#${subHref}`}
                    href={subHref}
                    isActive={activeSubItem === subHref}
                    isDisabled={subDisabled}
                    className="menu-items"
                    target="_blank"
                  >
                    {/* Render submenu icons */}
                    {subIcon && !isMobile && createElement(subIcon as any, { marginLeft: 10, marginRight: 10 })}
                    {subLabel}
                  </MenuItem>
                ))}
              </Flex>
            )}
          </div>
        );
      })}
    </Flex>
  );
};

export default memo(VMenuItems);
