import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <Svg viewBox="0 0 18 19" {...props}>
    <g clip-path="url(#clip0_614_11409)">
      <path
        d="M9.00003 18.2563C11.3391 18.2563 13.5377 17.3456 15.1915 15.6918C16.8456 14.0377 17.7563 11.839 17.7563 9.49997C17.7563 7.16094 16.8456 4.96228 15.1918 3.30849C13.5377 1.65437 11.3391 0.743652 9.00003 0.743652C6.661 0.743652 4.46234 1.65437 2.80822 3.30817C1.15443 4.96196 0.243711 7.16094 0.243711 9.49965C0.243711 11.8383 1.15443 14.0373 2.80822 15.6915C4.46202 17.3452 6.661 18.256 9.00003 18.256V18.2563ZM9.00003 1.08383C11.2481 1.08383 13.3616 1.95914 14.9511 3.54892C16.5405 5.13838 17.4162 7.25192 17.4162 9.49997C17.4162 11.748 16.5409 13.8616 14.9511 15.451C13.3616 17.0405 11.2481 17.9161 9.00003 17.9161C6.75198 17.9161 4.63844 17.0408 3.04898 15.451C1.45952 13.8616 0.583891 11.748 0.583891 9.49997C0.583891 7.25192 1.4592 5.13838 3.04898 3.54892C4.63844 1.95946 6.75198 1.08383 9.00003 1.08383Z"
        fill="black"
      />
      <path
        d="M9 18.5C11.404 18.5 13.6641 17.5639 15.364 15.864C17.0639 14.1641 18 11.904 18 9.5C18 7.09599 17.0639 4.83592 15.364 3.13599C13.6641 1.43606 11.404 0.5 9 0.5C6.59599 0.5 4.33592 1.43606 2.63599 3.13599C0.936064 4.83592 0 7.09599 0 9.5C0 11.904 0.936064 14.1641 2.63599 15.864C4.33592 17.5639 6.59599 18.5 9 18.5ZM9 1.32755C11.1831 1.32755 13.2352 2.17751 14.7788 3.72116C16.3225 5.2648 17.1725 7.31693 17.1725 9.5C17.1725 11.6831 16.3225 13.7352 14.7788 15.2788C13.2352 16.8225 11.1831 17.6725 9 17.6725C6.81693 17.6725 4.7648 16.8225 3.22116 15.2788C1.67751 13.7352 0.827543 11.6831 0.827543 9.5C0.827543 7.31693 1.67751 5.2648 3.22116 3.72116C4.7648 2.17751 6.81693 1.32755 9 1.32755Z"
        fill="url(#paint0_linear_614_11409)"
      />
      <path
        d="M6.36799 7.8924C6.36182 7.36475 6.35792 6.83385 6.36572 6.71233C6.44337 5.50822 7.48178 4.48605 8.73008 4.38565C8.80839 4.37915 8.88669 4.37558 8.96304 4.37493C9.6434 4.36778 10.2442 4.5806 10.7484 5.00688C11.2485 5.42991 11.5769 6.05341 11.6273 6.67497C11.6361 6.78674 11.6374 7.34785 11.6371 7.89338C12.1329 7.99183 12.5059 8.41518 12.5059 8.92204V13.5744C12.5059 14.1547 12.0175 14.6252 11.4151 14.6252H6.58535C5.98297 14.6252 5.49463 14.1547 5.49463 13.5744V8.92204C5.49463 8.41356 5.8699 7.98955 6.36799 7.8924ZM10.2984 6.18923C10.0193 5.735 9.56023 5.46825 9.03842 5.4572C9.01958 5.45655 9.00138 5.45655 8.98319 5.45688C8.45391 5.4624 7.99059 5.72396 7.71117 6.17493C7.55488 6.42738 7.49933 7.16785 7.48438 7.87161H10.5158C10.4966 7.1815 10.443 6.42479 10.2984 6.18923ZM7.3255 11.5665C8.13907 11.8144 8.41395 12.0893 8.66218 12.9032C8.70864 13.0552 8.8412 13.1537 9.00008 13.1537C9.15896 13.1537 9.29185 13.0552 9.33831 12.9032C9.58655 12.0893 9.86142 11.8144 10.675 11.5665C10.8271 11.52 10.9255 11.3875 10.9255 11.2286C10.9255 11.0697 10.8274 10.9365 10.675 10.8903C9.86142 10.6421 9.58655 10.3672 9.33831 9.55367C9.29185 9.40128 9.15929 9.30316 9.00008 9.30316C8.84088 9.30316 8.70864 9.40128 8.66218 9.55367C8.41395 10.3672 8.13907 10.6421 7.3255 10.8903C7.17344 10.9365 7.07499 11.0694 7.07499 11.2286C7.07499 11.3878 7.17344 11.52 7.3255 11.5665Z"
        fill="url(#paint1_linear_614_11409)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_614_11409"
        x1="8.99673"
        y1="-2.5692"
        x2="8.99673"
        y2="28.2016"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A088F2" />
        <stop offset="1" stop-color="#524FFB" />
        <stop offset="1.0001" stop-color="#524FFB" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_614_11409"
        x1="8.99897"
        y1="2.62695"
        x2="8.99897"
        y2="20.1499"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A088F2" />
        <stop offset="1" stop-color="#524FFB" />
        <stop offset="1.0001" stop-color="#524FFB" />
      </linearGradient>
      <clipPath id="clip0_614_11409">
        <rect width="18" height="18" fill="white" transform="matrix(-1 0 0 1 18 0.5)" />
      </clipPath>
    </defs>
  </Svg>
);

export default Icon;
