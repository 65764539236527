import { ChainId } from '@zinuswap/sdk'

export const SNAPSHOT_BASE_URL = process.env.NEXT_PUBLIC_SNAPSHOT_BASE_URL
export const SNAPSHOT_API = `${SNAPSHOT_BASE_URL}/graphql`
export const SNAPSHOT_HUB_API = `${SNAPSHOT_BASE_URL}/api/message`

export const INFO_CLIENT_MAINNETZ ='https://graph.mainnetz.io/subgraphs/name/zadi-exchange'
export const INFO_CLIENT_BSC = 'https://proxy-worker-api.pancakeswap.com/bsc-exchange'
export const INFO_CLIENT_BSC_TESTNET = 'https://api.thegraph.com/subgraphs/name/zinuswap/exchange-bsc-testnet'
export const INFO_CLIENT_ETHEREUM = 'https://api.thegraph.com/subgraphs/name/pancakeswap/exhange-eth' // TODO-SD
export const INFO_CLIENT_GOERLI = 'https://api.thegraph.com/subgraphs/name/zinuswap/exchange-goerli' // TODO-SD
export const BLOCKS_CLIENT_MAINNETZ='https://graph.mainnetz.io/subgraphs/name/zadi-exchange/block'
export const BLOCKS_CLIENT_BSC = 'https://api.thegraph.com/subgraphs/name/pancakeswap/blocks'
export const BLOCKS_CLIENT_BSC_TESTNET = 'https://api.thegraph.com/subgraphs/name/zinuswap/blocks-bsc-testnet'
export const BLOCKS_CLIENT_ETHEREUM = 'https://api.thegraph.com/subgraphs/name/blocklytics/ethereum-blocks'
export const BLOCKS_CLIENT_GOERLI = 'https://api.thegraph.com/subgraphs/name/blocklytics/goerli-blocks'
export const GRAPH_HEALTH = 'https://api.thegraph.com/index-node/graphql'

export const FARM_API = 'https://farms.zombieinu-swap.workers.dev'

export const BIT_QUERY = 'https://graphql.bitquery.io'

export const CELER_API = 'https://api.celerscan.com/scan'

export const INFO_CLIENT_WITH_CHAIN = {

  [ChainId.MAINNETZ]:INFO_CLIENT_MAINNETZ,
  
  [ChainId.BSC]: INFO_CLIENT_BSC,
  [ChainId.ETHEREUM]: INFO_CLIENT_ETHEREUM,
  [ChainId.BSC_TESTNET]: INFO_CLIENT_BSC_TESTNET,
  [ChainId.GOERLI]: INFO_CLIENT_GOERLI,
}

export const BLOCKS_CLIENT_WITH_CHAIN = {
  [ChainId.MAINNETZ]: BLOCKS_CLIENT_MAINNETZ,
  [ChainId.BSC]: BLOCKS_CLIENT_BSC,
  [ChainId.ETHEREUM]: BLOCKS_CLIENT_ETHEREUM,
  [ChainId.BSC_TESTNET]: BLOCKS_CLIENT_BSC_TESTNET,
  [ChainId.GOERLI]: BLOCKS_CLIENT_GOERLI,
}
