import { FixedNumber } from '@ethersproject/bignumber'
import { getFullDecimalMultiplier } from './getFullDecimalMultiplier'

export const FIXED_ZERO = FixedNumber.from(0)
export const FIXED_ONE = FixedNumber.from(1)
export const FIXED_TWO = FixedNumber.from(2)

export const masterChefAddresses = {
  8453:'0xbe2CF34842F6110bf89F601b7aE876Fb1F203A16',
  9768:'0x9C40aa4514Bcd52789Ab01fdEB60DE7114d112B3',
  97: '0x1F8ffa3F84a4F13f9e5bA960787B01E2E57e5777', // SOLVED-SD
  56: '0x13884AF5554230D09caC64fB21a7426AC6Ba735B', // TODO-SD
  1: '0x81829eb6bf1344043a019161b20714795196b904', // SOLVED-SD
  5: '0xD349c5b4Ce55294e1104d68A47E0F63972de4D64', // SOLVED-SD
}
