import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 18 19" {...props}>
      <g clip-path="url(#clip0_425_2200)">
        <path
          d="M9.00003 17.8544C11.3391 17.8544 13.5377 16.9436 15.1915 15.2899C16.8456 13.6357 17.7563 11.4371 17.7563 9.09805C17.7563 6.75903 16.8456 4.56036 15.1918 2.90657C13.5377 1.25246 11.3391 0.341736 9.00003 0.341736C6.661 0.341736 4.46234 1.25246 2.80822 2.90625C1.15443 4.56004 0.243711 6.75903 0.243711 9.09773C0.243711 11.4364 1.15443 13.6354 2.80822 15.2895C4.46202 16.9433 6.661 17.854 9.00003 17.854V17.8544ZM9.00003 0.681916C11.2481 0.681916 13.3616 1.55722 14.9511 3.14701C16.5405 4.73646 17.4162 6.85 17.4162 9.09805C17.4162 11.3461 16.5409 13.4596 14.9511 15.0491C13.3616 16.6386 11.2481 17.5142 9.00003 17.5142C6.75198 17.5142 4.63844 16.6389 3.04898 15.0491C1.45952 13.4596 0.583891 11.3461 0.583891 9.09805C0.583891 6.85 1.4592 4.73646 3.04898 3.14701C4.63844 1.55755 6.75198 0.681916 9.00003 0.681916Z"
          fill="black"
        />
        <path
          d="M9 18.0981C11.404 18.0981 13.6641 17.162 15.364 15.4621C17.0639 13.7621 18 11.5021 18 9.09805C18 6.69405 17.0639 4.43397 15.364 2.73405C13.6641 1.03412 11.404 0.098053 9 0.098053C6.59599 0.098053 4.33592 1.03412 2.63599 2.73405C0.936064 4.43397 0 6.69405 0 9.09805C0 11.5021 0.936064 13.7621 2.63599 15.4621C4.33592 17.162 6.59599 18.0981 9 18.0981ZM9 0.925598C11.1831 0.925598 13.2352 1.77556 14.7788 3.31921C16.3225 4.86285 17.1725 6.91498 17.1725 9.09805C17.1725 11.2811 16.3225 13.3333 14.7788 14.8769C13.2352 16.4205 11.1831 17.2705 9 17.2705C6.81693 17.2705 4.7648 16.4205 3.22116 14.8769C1.67751 13.3333 0.827543 11.2811 0.827543 9.09805C0.827543 6.91498 1.67751 4.86285 3.22116 3.31921C4.7648 1.77556 6.81693 0.925598 9 0.925598Z"
          fill="url(#paint0_linear_425_2200)"
        />
        <path
          d="M8.99997 15.7863C10.7866 15.7863 12.4661 15.0907 13.7294 13.8274C14.9926 12.5642 15.6882 10.8847 15.6882 9.09806C15.6882 7.31138 14.9926 5.63192 13.7294 4.36867C12.4661 3.10542 10.7866 2.40979 8.99997 2.40979C7.21329 2.40979 5.53383 3.10542 4.27058 4.36867C3.00733 5.63192 2.3117 7.31138 2.3117 9.09806C2.3117 10.8847 3.00733 12.5642 4.27058 13.8274C5.53383 15.0907 7.21329 15.7863 8.99997 15.7863ZM8.99997 2.74997C10.6957 2.74997 12.2897 3.41019 13.4889 4.60943C14.6878 5.80835 15.3484 7.40268 15.3484 9.09838C15.3484 10.7941 14.6882 12.3881 13.4889 13.5873C12.29 14.7863 10.6957 15.4468 8.99997 15.4468C7.30426 15.4468 5.71025 14.7866 4.51101 13.5873C3.3121 12.3884 2.65155 10.7941 2.65155 9.09838C2.65155 7.40268 3.31177 5.80867 4.51101 4.60943C5.70993 3.41051 7.30426 2.74997 8.99997 2.74997Z"
          fill="black"
        />
        <path
          d="M8.99993 16.03C10.8516 16.03 12.5925 15.309 13.9015 13.9996C15.2109 12.6903 15.9319 10.9494 15.9319 9.09806C15.9319 7.24672 15.2109 5.50585 13.9015 4.19647C12.5921 2.88708 10.8516 2.16611 8.99993 2.16611C7.14827 2.16611 5.40741 2.88708 4.09835 4.19647C2.78896 5.50585 2.06798 7.2464 2.06798 9.09806C2.06798 10.9497 2.78896 12.6906 4.09835 13.9996C5.40773 15.309 7.1486 16.03 8.99993 16.03ZM8.99993 2.99365C10.6303 2.99365 12.1636 3.62853 13.3164 4.78163C14.4695 5.93474 15.1043 7.46766 15.1043 9.09806C15.1043 10.7285 14.4695 12.2617 13.3164 13.4145C12.1633 14.5676 10.6303 15.2025 8.99993 15.2025C7.36954 15.2025 5.83629 14.5676 4.68351 13.4145C3.53073 12.2614 2.89553 10.7285 2.89553 9.09806C2.89553 7.46766 3.5304 5.93441 4.68351 4.78163C5.83661 3.62885 7.36954 2.99365 8.99993 2.99365Z"
          fill="url(#paint1_linear_425_2200)"
        />
        <path
          d="M9.00005 13.0213C9.23464 13.0213 9.43024 12.8764 9.49879 12.6519C10.0593 10.8136 10.7159 10.1569 12.5539 9.59677C12.7784 9.52821 12.9233 9.33261 12.9233 9.09803C12.9233 8.86344 12.7784 8.66785 12.5539 8.59929C10.7159 8.03882 10.0589 7.38218 9.49879 5.54417C9.43024 5.31965 9.23464 5.17474 9.00005 5.17474C8.76547 5.17474 8.56987 5.31965 8.50132 5.54417C7.94085 7.38218 7.28421 8.03915 5.44619 8.59929C5.22168 8.66785 5.07677 8.86344 5.07677 9.09803C5.07677 9.33261 5.22168 9.52821 5.44619 9.59677C7.28421 10.1572 7.94117 10.8139 8.50132 12.6519C8.56987 12.8764 8.76547 13.0213 9.00005 13.0213ZM9.00005 5.5146C9.03157 5.5146 9.13684 5.52402 9.17323 5.64326C9.48417 6.66348 9.8237 7.30518 10.3081 7.78994C10.7926 8.27438 11.4346 8.61391 12.4548 8.92485C12.5741 8.96124 12.5835 9.06684 12.5835 9.09803C12.5835 9.12922 12.5741 9.23482 12.4548 9.27121C11.4346 9.58214 10.7929 9.92168 10.3081 10.4061C9.8237 10.8906 9.48417 11.5326 9.17323 12.5528C9.13684 12.672 9.03125 12.6815 9.00005 12.6815C8.96886 12.6815 8.86327 12.672 8.82688 12.5528C8.51594 11.5326 8.17641 10.8909 7.69197 10.4061C7.20753 9.92168 6.56551 9.58214 5.54529 9.27121C5.42605 9.23482 5.41662 9.12922 5.41662 9.09803C5.41662 9.06684 5.42605 8.96124 5.54529 8.92485C6.56551 8.61391 7.2072 8.27438 7.69197 7.78994C8.17641 7.3055 8.51594 6.66348 8.82688 5.64326C8.86327 5.52402 8.96886 5.5146 9.00005 5.5146Z"
          fill="black"
        />
        <path
          d="M8.99994 13.265C9.34402 13.265 9.63124 13.0522 9.73164 12.7227C10.2687 10.9614 10.8633 10.3665 12.625 9.82941C12.9544 9.72901 13.1672 9.44179 13.1672 9.09771C13.1672 8.75363 12.9544 8.46641 12.6253 8.36602C10.864 7.82894 10.269 7.23403 9.73197 5.47269C9.63157 5.14324 9.34435 4.93042 9.00027 4.93042C8.65619 4.93042 8.36897 5.14324 8.26857 5.47269C7.7315 7.23403 7.13691 7.82894 5.37525 8.36602C5.04579 8.46641 4.83298 8.75363 4.83298 9.09771C4.83298 9.44179 5.04579 9.72901 5.37525 9.82941C7.13659 10.3665 7.7315 10.9611 8.26857 12.7227C8.36897 13.0522 8.65619 13.265 9.00027 13.265H8.99994ZM12.1932 9.09804C11.2412 9.40605 10.619 9.75078 10.1358 10.2339C9.65269 10.7171 9.30796 11.3393 8.99994 12.2913C8.69193 11.3393 8.3472 10.7171 7.86406 10.2339C7.38092 9.75078 6.75872 9.40605 5.80673 9.09804C6.75872 8.79002 7.38092 8.44529 7.86406 7.96215C8.3472 7.47901 8.69193 6.85681 8.99994 5.90482C9.30796 6.85681 9.65269 7.47901 10.1358 7.96215C10.619 8.44529 11.2412 8.79002 12.1932 9.09804Z"
          fill="url(#paint2_linear_425_2200)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_425_2200"
          x1="8.99673"
          y1="-2.97114"
          x2="8.99673"
          y2="27.7997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A088F2" />
          <stop offset="1" stop-color="#524FFB" />
          <stop offset="1.0001" stop-color="#524FFB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_425_2200"
          x1="8.99742"
          y1="-0.197839"
          x2="8.99742"
          y2="23.5023"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A088F2" />
          <stop offset="1" stop-color="#524FFB" />
          <stop offset="1.0001" stop-color="#524FFB" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_425_2200"
          x1="8.99859"
          y1="3.50928"
          x2="8.99859"
          y2="17.7572"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A088F2" />
          <stop offset="1" stop-color="#524FFB" />
          <stop offset="1.0001" stop-color="#524FFB" />
        </linearGradient>
        <clipPath id="clip0_425_2200">
          <rect width="18" height="18" fill="white" transform="matrix(-1 0 0 1 18 0.098053)" />
        </clipPath>
      </defs>
    </Svg>
  );
};
export default Icon;
