import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 12 12" {...props}>
      <path
        d="M0.659373 11.4466C1.1091 11.8964 1.76268 11.9936 2.32452 11.6942C5.32867 10.094 6.8834 10.0936 9.88798 11.6942C10.4498 11.9936 11.1034 11.8964 11.5531 11.4466C12.0029 10.9969 12.1001 10.3433 11.8011 9.78191C10.201 6.77776 10.201 5.2226 11.8011 2.21845C12.1005 1.65661 12.0033 1.00304 11.5535 0.553306C11.1038 0.103576 10.4502 0.00632442 9.8884 0.30572C6.88425 1.90589 5.32952 1.90632 2.32495 0.305722C1.7631 0.00632636 1.10953 0.103576 0.659797 0.553306C0.210066 1.00304 0.112816 1.65661 0.412212 2.21846C2.01239 5.2226 2.01281 6.77734 0.412212 9.78191C0.112817 10.3438 0.210067 10.9973 0.659798 11.4471L0.659373 11.4466ZM10.2795 10.1739C8.63264 9.33218 7.36881 8.96951 6.10582 8.96951C4.84284 8.96951 3.57901 9.33218 1.93212 10.1739C2.77383 8.527 3.1365 7.26316 3.1365 6.00018C3.1365 4.7372 2.77383 3.47337 1.93212 1.82648C3.57901 2.66819 4.84284 3.03086 6.10582 3.03086C7.36881 3.03086 8.63264 2.66819 10.2795 1.82648C9.43782 3.47337 9.07515 4.7372 9.07515 6.00018C9.07515 7.26316 9.43782 8.527 10.2795 10.1739Z"
        fill="url(#paint0_linear_199_27515)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_199_27515"
          x1="13.4089"
          y1="-1.30639"
          x2="-5.21389"
          y2="17.3164"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A088F2" />
          <stop offset="1" stop-color="#524FFB" />
          <stop offset="1.0001" stop-color="#524FFB" />
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default Icon;
