import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 18 19" {...props}>
      <g clip-path="url(#clip0_425_2256)">
        <path
          d="M12.3011 6.70381C12.0027 6.70381 11.7052 6.72838 11.4114 6.77569C11.9263 3.64573 9.8065 0.690471 6.67686 0.175483C3.54721 -0.339504 0.592249 1.77959 0.0773134 4.91046C-0.437622 8.04042 1.68217 10.9957 4.81181 11.5107C5.42955 11.6126 6.06003 11.6126 6.67777 11.5107C6.18649 14.6179 8.30718 17.5358 11.4141 18.0272C14.521 18.5185 17.4386 16.3976 17.9299 13.2904C18.4212 10.1832 16.3005 7.26521 13.1936 6.77388C12.8988 6.72747 12.6004 6.70381 12.302 6.70381H12.3011ZM12.3011 9.145C14.0988 9.145 15.5563 10.6026 15.5563 12.4005C15.5563 14.1984 14.0988 15.656 12.3011 15.656C10.5034 15.656 9.04593 14.1984 9.04593 12.4005C9.04775 10.6035 10.5043 9.14682 12.3011 9.145ZM0.908852 5.89039C0.908852 3.19353 3.09505 1.00711 5.79164 1.00711C8.48823 1.00711 10.6744 3.19353 10.6744 5.89039C10.6744 8.58725 8.48823 10.7737 5.79164 10.7737C3.09596 10.7709 0.912492 8.58634 0.908852 5.89039ZM12.3011 17.2838C9.60544 17.2811 7.42197 15.0965 7.41833 12.4005C7.42106 12.0311 7.46655 11.6626 7.55389 11.3041C7.89142 11.1949 8.21712 11.0539 8.52826 10.8838C7.6849 12.9728 8.69475 15.3494 10.7827 16.1929C12.8716 17.0363 15.2479 16.0264 16.0913 13.9382C16.9346 11.8491 15.9248 9.47256 13.8368 8.6291C12.857 8.23331 11.7625 8.23331 10.7827 8.6291C10.9528 8.31793 11.0938 7.99219 11.203 7.65463C11.5624 7.56637 11.9299 7.52088 12.2993 7.51906C14.9959 7.51906 17.1821 9.70548 17.1821 12.4023C17.1821 15.0992 14.9959 17.2856 12.2993 17.2856L12.3011 17.2838Z"
          fill="url(#paint0_linear_425_2256)"
        />
        <path
          d="M11.4877 1.82044H14.3362C15.0104 1.82044 15.5572 2.36727 15.5572 3.04149V4.90763L15.438 4.78844C15.2761 4.63194 15.0186 4.63649 14.863 4.79845C14.7111 4.95586 14.7111 5.20607 14.863 5.36348L15.6764 6.1769C15.8356 6.33431 16.0921 6.33431 16.2513 6.1769L17.0647 5.36348C17.2212 5.20152 17.2166 4.94403 17.0547 4.78844C16.8973 4.63649 16.6471 4.63649 16.4897 4.78844L16.3705 4.90763V3.04149C16.3696 1.9187 15.4589 1.00792 14.3362 1.00701H11.4877C11.263 1.00701 11.0811 1.18899 11.0811 1.41372C11.0811 1.63846 11.263 1.82044 11.4877 1.82044Z"
          fill="url(#paint1_linear_425_2256)"
        />
        <path
          d="M6.60499 16.4694H3.75647C3.08232 16.4694 2.53555 15.9225 2.53555 15.2483V13.3822L2.65473 13.5014C2.81667 13.6579 3.07414 13.6533 3.22971 13.4914C3.38164 13.334 3.38164 13.0837 3.22971 12.9263L2.41637 12.1129C2.25533 11.9591 2.00242 11.9591 1.84138 12.1129L1.02804 12.9263C0.871559 13.0883 0.876108 13.3458 1.03805 13.5014C1.19544 13.6533 1.44563 13.6533 1.60302 13.5014L1.7222 13.3822V15.2483C1.72311 16.3711 2.6338 17.2819 3.75647 17.2828H6.60499C6.82971 17.2828 7.01166 17.1008 7.01166 16.8761C7.01166 16.6514 6.82971 16.4694 6.60499 16.4694Z"
          fill="url(#paint2_linear_425_2256)"
        />
        <path
          d="M8.06599 5.8903C8.06599 6.07773 7.94954 6.23514 7.7694 6.28973C6.80867 6.58271 6.48388 6.90753 6.19093 7.86927C6.13635 8.04851 5.97895 8.16498 5.79154 8.16498C5.60413 8.16498 5.44673 8.04851 5.39215 7.86927C5.0992 6.90753 4.77441 6.58271 3.81277 6.28973C3.63354 6.23514 3.51709 6.07773 3.51709 5.8903C3.51709 5.70286 3.63354 5.54545 3.81277 5.49086C4.77441 5.19788 5.0992 4.87306 5.39215 3.91132C5.44673 3.73208 5.60413 3.61562 5.79154 3.61562C5.97895 3.61562 6.13635 3.73208 6.19093 3.91132C6.48388 4.87306 6.80867 5.19788 7.77031 5.49086C7.94954 5.54545 8.06599 5.70286 8.06599 5.8903Z"
          fill="url(#paint3_linear_425_2256)"
        />
        <path
          d="M14.5755 12.4204C14.5755 12.6079 14.4591 12.7653 14.2789 12.8199C13.3182 13.1129 12.9934 13.4377 12.7005 14.3994C12.6459 14.5787 12.4885 14.6951 12.3011 14.6951C12.1136 14.6951 11.9563 14.5787 11.9017 14.3994C11.6087 13.4377 11.2839 13.1129 10.3223 12.8199C10.1431 12.7653 10.0266 12.6079 10.0266 12.4204C10.0266 12.233 10.1431 12.0756 10.3223 12.021C11.2839 11.728 11.6087 11.4032 11.9017 10.4415C11.9563 10.2622 12.1136 10.1458 12.3011 10.1458C12.4885 10.1458 12.6459 10.2622 12.7005 10.4415C12.9934 11.4032 13.3182 11.728 14.2798 12.021C14.4591 12.0756 14.5755 12.233 14.5755 12.4204Z"
          fill="url(#paint4_linear_425_2256)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_425_2256"
          x1="9.00372"
          y1="-2.97073"
          x2="9.00372"
          y2="27.7992"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A088F2" />
          <stop offset="1" stop-color="#524FFB" />
          <stop offset="1.0001" stop-color="#524FFB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_425_2256"
          x1="14.1312"
          y1="0.105358"
          x2="14.1312"
          y2="9.14505"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A088F2" />
          <stop offset="1" stop-color="#524FFB" />
          <stop offset="1.0001" stop-color="#524FFB" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_425_2256"
          x1="3.96373"
          y1="11.0964"
          x2="3.96373"
          y2="20.1314"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A088F2" />
          <stop offset="1" stop-color="#524FFB" />
          <stop offset="1.0001" stop-color="#524FFB" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_425_2256"
          x1="5.79237"
          y1="2.8399"
          x2="5.79237"
          y2="10.617"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A088F2" />
          <stop offset="1" stop-color="#524FFB" />
          <stop offset="1.0001" stop-color="#524FFB" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_425_2256"
          x1="12.3019"
          y1="9.37005"
          x2="12.3019"
          y2="17.1471"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A088F2" />
          <stop offset="1" stop-color="#524FFB" />
          <stop offset="1.0001" stop-color="#524FFB" />
        </linearGradient>
        <clipPath id="clip0_425_2256">
          <rect width="18" height="18" fill="white" transform="translate(0 0.098053)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
