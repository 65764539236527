import { ChainId } from '@zinuswap/sdk'
import { getFullDecimalMultiplier } from '@zinuswap/utils/getFullDecimalMultiplier'

export const BLOCK_TIME = {
    [ChainId.MAINNETZ]:3,
    [ChainId.BSC]: 3,
    [ChainId.BSC_TESTNET]: 3,
    [ChainId.ETHEREUM]: 12,
    [ChainId.GOERLI]: 12
}

// ZADI_PER_BLOCK details
// 5 ZADI is minted per block
// 20 ZADI per block is sent to Burn pool (A farm just for burning zinu)
// 10 ZADI per block goes to ZADI syrup pool
// 9 ZADI per block goes to Yield farms
// ZADI_PER_BLOCK in config/index.ts = 5 as we only change the amount sent to the burn pool which is effectively a farm.
// ZINI/Block in src/views/Home/components/ZinuDataRow.tsx = 15 (5 - Amount sent to burn pool)
export const ZADI_PER_BLOCK = {
    [ChainId.MAINNETZ]:5,
    [ChainId.BSC]: 5, // TODO-SD
    [ChainId.BSC_TESTNET]: 5, // TODO-SD
    [ChainId.ETHEREUM]: 20, // TODO-SD
    [ChainId.GOERLI]: 20, // TODO-SD
}
export const BLOCKS_PER_DAY = {
    [ChainId.MAINNETZ]:(60 / BLOCK_TIME[ChainId.MAINNETZ]) * 60 * 24,
    [ChainId.BSC]: (60 / BLOCK_TIME[ChainId.BSC]) * 60 * 24,
    [ChainId.BSC_TESTNET]: (60 / BLOCK_TIME[ChainId.BSC_TESTNET]) * 60 * 24,
    [ChainId.ETHEREUM]: (60 / BLOCK_TIME[ChainId.ETHEREUM]) * 60 * 24,
    [ChainId.GOERLI]: (60 / BLOCK_TIME[ChainId.GOERLI]) * 60 * 24,
}
export const BLOCKS_PER_YEAR = {
    [ChainId.MAINNETZ]: BLOCKS_PER_DAY[ChainId.MAINNETZ] * 365,
    [ChainId.BSC]: BLOCKS_PER_DAY[ChainId.BSC] * 365,
    [ChainId.BSC_TESTNET]: BLOCKS_PER_DAY[ChainId.BSC_TESTNET] * 365,
    [ChainId.ETHEREUM]: BLOCKS_PER_DAY[ChainId.ETHEREUM] * 365,
    [ChainId.GOERLI]: BLOCKS_PER_DAY[ChainId.GOERLI] * 365,
}
export const ZINU_PER_YEAR = {
    [ChainId.MAINNETZ]: ZADI_PER_BLOCK[ChainId.MAINNETZ] * BLOCKS_PER_YEAR[ChainId.BSC],
    [ChainId.BSC]: ZADI_PER_BLOCK[ChainId.BSC] * BLOCKS_PER_YEAR[ChainId.BSC],
    [ChainId.BSC_TESTNET]: ZADI_PER_BLOCK[ChainId.BSC_TESTNET] * BLOCKS_PER_YEAR[ChainId.BSC_TESTNET],
    [ChainId.ETHEREUM]: ZADI_PER_BLOCK[ChainId.ETHEREUM] * BLOCKS_PER_YEAR[ChainId.ETHEREUM],
    [ChainId.GOERLI]: ZADI_PER_BLOCK[ChainId.GOERLI] * BLOCKS_PER_YEAR[ChainId.GOERLI],
}
export const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL
export const BASE_ADD_LIQUIDITY_URL = `${BASE_URL}/add`
export const DEFAULT_TOKEN_DECIMAL = getFullDecimalMultiplier(18) // CHECKED-SD
export const DEFAULT_ZINU_DECIMAL = getFullDecimalMultiplier(9) // CHECKED-SD
export const DEFAULT_GAS_LIMIT = 250000
export const STAKING_GAS_LIMIT = 500000
export const BOOSTED_FARM_GAS_LIMIT = 500000
export const IPFS_GATEWAY = 'https://ipfs.io/ipfs'
