import { useIsMounted } from "@zinuswap/hooks";
import { AtomBox } from "@zinuswap/ui/components/AtomBox";
import throttle from "lodash/throttle";
import React, { useEffect, useRef, useState, createElement } from "react";
import styled from "styled-components";
import BottomNav from "../../components/BottomNav";
import { Box } from "../../components/Box";
import Flex from "../../components/Box/Flex";
import ZinuPrice from "../../components/ZinuPrice/ZinuPrice";
import Footer from "../../components/Footer";
import LangSelector from "../../components/LangSelector/LangSelector";
import VMenuItems from "../../components/MenuItems/VMenuItems";
import { SubMenuItems } from "../../components/SubMenuItems";
import { Button } from "../../components/Button";
import { useMatchBreakpoints } from "../../contexts";
import Logo from "./components/Logo";
import { MENU_HEIGHT, MOBILE_MENU_HEIGHT, TOP_BANNER_HEIGHT, TOP_BANNER_HEIGHT_MOBILE } from "./config";
import { MenuContext } from "./context";
import { NavProps } from "./types";
import MobLogo from "./components/MobLogo";
import {
  HamburgerCloseIcon,
  HamburgerIcon,
  IconButton,
  OverviewIcon,
  MarkerIcon,
  TwitterIcon,
  TelegramIcon,
  ZombieInuRoundIcon,
} from "../../components";
import { Text } from "@zinuswap/uikit";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: calc(100vh - ${MENU_HEIGHT}px);

  ${({ theme }) => theme.mediaQueries.sm} {
    display: grid;
    grid-template-columns: 240px 1fr;
    min-height: 100vh;
  }
`;

const StyledNav = styled.nav<{ showMobMenu: boolean }>`
  position: absolute;
  z-index: 10;
  left: ${({ showMobMenu }) => (showMobMenu ? "0" : "-240px")};
  -webkit-animation: ${({ showMobMenu }) => (showMobMenu ? "pushmobmenu" : "popmobmenu")} 0.2s linear 1;
  animation: ${({ showMobMenu }) => (showMobMenu ? "pushmobmenu" : "popmobmenu")} 0.2s linear 1;
  display: flex;
  flex-direction: column;
  width: 240px;
  height: 100%;
  background: #141414;
  transform: translate3d(0, 0, 0);
  padding-bottom: 20px;

  ${({ theme }) => theme.mediaQueries.sm} {
    position: relative;
    display: flex;
    width: 100%;
    left: 0;
  }

  @keyframes pushmobmenu {
    0% {
      left: -240px;
    }
    100% {
      left: 0;
    }
  }
  @keyframes popmobmenu {
    0% {
      left: 0px;
    }
    100% {
      left: -240px;
    }
  }
`;

const StyledTopNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  // border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
  transform: translate3d(0, 0, 0);

  padding-left: 16px;
  padding-right: 16px;

  ${({ theme }) => theme.mediaQueries.sm} {
    justify-content: flex-end;
  }
`;

const FixedContainer = styled.div<{ showMenu: boolean; height: number }>`
  position: fixed;
  top: ${({ showMenu, height }) => (showMenu ? 0 : `-${height}px`)};
  transition: top 0.2s;
  height: ${({ height }) => `${height}px`};
  width: 100%;
  z-index: 20;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: calc(100% - 240px);
  }
`;

const TopBannerContainer = styled.div<{ height: number }>`
  height: ${({ height }) => `${height}px`};
  min-height: ${({ height }) => `${height}px`};
  max-height: ${({ height }) => `${height}px`};
  width: 100%;
`;

const BodyWrapper = styled(Box)`
  position: relative;
  display: flex;
  max-width: 100vw;
`;

const Inner = styled.div`
  flex-grow: 1;
  transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);
  max-width: 100%;
`;

const MobLogoWrap = styled.div`
  display: flex;
  gap: 10px;

  ${({ theme }) => theme.mediaQueries.lg} {
    display: none;
  }
`;

const NetworkLabel = styled.div`
  width: fit-content;
  background-color: #000000;
  color: #8d77df;
  font-weight: 500;
  border-radius: 12px;
  padding: 8px 12px;

  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 53px;
`;

const HeaderText = styled.div`
  display: flex;
  margin-top: auto;
  margin-bottom: 80px;
  align-items: center;
  margin-right: 0.9rem;
  margin-left: 0.9rem;
  font-size: 0.825rem;
  font-weight: 500;
  opacity: 0.8;
  :hover {
    opacity: 1;
  }
  a {
    margin-right: 0.5rem;
    color: white;
  }
`;

const FooterMenu = styled.div`
  display: flex;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  justify-content: center;
  gap: 10px;
  border-top: 1px solid #ffffff1a;
  width: 100%;
  z-index: 3;
  margin-top: auto;
  position: absolute;
  bottom: 0;
`;

const FooterSocials = styled.div`
  display: flex;
  align-items: center;
  justify-items: space-between;
  width: 100%;
  padding: 0px 25px;
`;

const Link = `
  color: blue;
`;

const SidebarContainer = styled.nav<{ showMobMenu: boolean }>`
  display: ${({ showMobMenu }) => (showMobMenu ? "block" : "none")};
  background: #141414;
  gap: 25px;
  position: fixed;
  width: 15rem;
  height: 100%;
  -webkit-animation: ${({ showMobMenu }) => (showMobMenu ? "pushmobmenu" : "popmobmenu")} 0.2s linear 1;
  animation: ${({ showMobMenu }) => (showMobMenu ? "pushmobmenu" : "popmobmenu")} 0.2s linear 1;
  z-index: 60;
  overflow: auto;
  border-radius: 10px;

  ${({ theme }) => theme.mediaQueries.lg} {
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 50;
    flex-direction: column;
    width: 15rem;
  }

  @keyframes pushmobmenu {
    0% {
      left: -240px;
    }
    100% {
      left: 0;
    }
  }
  @keyframes popmobmenu {
    0% {
      left: 0px;
    }
    100% {
      left: -240px;
    }
  }
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  @media (min-width: 1024px) {
    padding-left: 15rem;
  }
`;

const NavContainer = styled.nav<{ showMenu: boolean }>`
  display: flex;
  position: sticky;
  top: 0;
  z-index: 40;
  padding-left: 1rem;
  padding-right: 1rem;
  shrink: 0;
  column-gap: 1rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom-width: 1px;
  border-color: #e5e7eb;
  height: 5rem;
  background-color: #141414;
  border-bottom: 1px solid #141414;

  @media (min-width: 640px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    column-gap: 1.5rem;
  }

  @media (min-width: 1024px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

const MainContainer = styled.div`
  min-height: 100vh;
  height: 100%;
  width: 100%;
  position: relative;
`;

const TitleContainer = styled.div`
  display: none;
  align-items: center;
  gap: 10px;

  ${({ theme }) => theme.mediaQueries.lg} {
    display: flex;
  }
`;

const MenuContainer = styled.div<{ isMobile?: boolean }>`
  display: ${({ isMobile }) => (!isMobile ? "none" : "block")};
  ${({ theme }) => theme.mediaQueries.lg} {
    display: ${({ isMobile }) => (!isMobile ? "block" : "none")};
  }
`;

const Menu: React.FC<React.PropsWithChildren<NavProps>> = ({
  linkComponent = "a",
  banner,
  rightSide,
  isDark,
  toggleTheme,
  currentLang,
  setLang,
  zinuPriceUsd,
  links,
  subLinks,
  footerLinks,
  activeItem,
  activeSubItem,
  langs,
  buyZinuLabel,
  children,
}) => {
  const { isMobile } = useMatchBreakpoints();
  const isMounted = useIsMounted();
  const [showMenu, setShowMenu] = useState(true);
  const [showMobMenu, setShowMobMenu] = useState(false);
  const refPrevOffset = useRef(typeof window === "undefined" ? 0 : window.pageYOffset);

  const topBannerHeight = isMobile ? TOP_BANNER_HEIGHT_MOBILE : TOP_BANNER_HEIGHT;

  const totalTopMenuHeight = isMounted && banner ? MENU_HEIGHT + topBannerHeight : MENU_HEIGHT;

  useEffect(() => {
    const handleScroll = () => {
      const currentOffset = window.pageYOffset;
      const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight;
      const isTopOfPage = currentOffset === 0;
      // Always show the menu when user reach the top
      if (isTopOfPage) {
        setShowMenu(true);
      }
      // Avoid triggering anything at the bottom because of layout shift
      else if (!isBottomOfPage) {
        if (currentOffset < refPrevOffset.current || currentOffset <= totalTopMenuHeight) {
          // Has scroll up
          setShowMenu(true);
        } else {
          // Has scroll down
          setShowMenu(false);
        }
      }
      refPrevOffset.current = currentOffset;
    };
    const throttledHandleScroll = throttle(handleScroll, 200);

    window.addEventListener("scroll", throttledHandleScroll);
    return () => {
      window.removeEventListener("scroll", throttledHandleScroll);
    };
  }, [totalTopMenuHeight]);

  // Find the home link if provided
  const homeLink = links.find((link) => link.label === "Home");
  // links.map(({ label, items: menuItems = [], href, icon, disabled }) => {
  //   console.log(label)
  // };
  const activeSubItems = links.find((link) => {
    return link.href == activeItem;
  });
  const activeLink =
    activeItem &&
    activeSubItems.items.find((link) => {
      return link.href == activeSubItem;
    });

  return (
    <MenuContext.Provider value={{ linkComponent }}>
      <AtomBox
        minHeight={{
          xs: "auto",
          md: "100vh",
        }}
        className="dapp"
        style={{zIndex:999}}
      >
        <SidebarContainer showMobMenu={showMobMenu} className="dapp-sidebar">
          <div style={{ padding: "10px" }}>
            <Logo href={homeLink?.href ?? "/"} />
          </div>
          <MenuContainer isMobile={false}>
            <VMenuItems items={links} activeItem={activeItem} activeSubItem={activeSubItem} isMobile={false} />
          </MenuContainer>
          <MenuContainer isMobile={true}>
            <VMenuItems items={links} activeItem={activeItem} activeSubItem={activeSubItem} isMobile={true} />
          </MenuContainer>

          <FooterMenu>
            <a href="https://t.me/ZodiacSwapDEX" target="_blank">
              <TelegramIcon />
            </a>
            <a href="https://twitter.com/ZodiacSwapDEX" target="_blank">
              <TwitterIcon></TwitterIcon>
            </a>

            <a href="https://zodiacswap.com/" target="_blank">
              <ZombieInuRoundIcon></ZombieInuRoundIcon>
            </a>
          </FooterMenu>
          <img
            src="/images/gradient-nav.webp"
            alt=""
            style={{ position: "absolute", bottom: "0px", left: "0", width: "100%", height: "100%" }}
          />
        </SidebarContainer>

        <MainWrapper className="dapp-wrapper">
          {showMobMenu && (
            <div
              onClick={() => {
                setShowMobMenu(!showMobMenu);
              }}
              style={{ background: "#1C1C1CE6", width: "100%", height: "100%", position: "fixed", zIndex: 50 }}
            >
              <div
                onClick={() => {
                  setShowMobMenu(!showMobMenu);
                }}
                style={{ position: "absolute", right: "20px", top: "20px", background: "none", cursor: "pointer" }}
              >
                <MarkerIcon />
              </div>
            </div>
          )}
          <NavContainer showMenu={showMenu} className="dapp-navbar">
            <AtomBox display={{ xs: "none", lg: "block" }}>
              <TitleContainer>
                <OverviewIcon />
                <Text>{activeLink ? activeLink.label : "Overview"}</Text>
              </TitleContainer>
            </AtomBox>

            <MobLogoWrap>
              <IconButton
                scale="sm"
                variant="text"
                onClick={() => {
                  setShowMobMenu(!showMobMenu);
                }}
              >
                {showMobMenu ? <HamburgerCloseIcon width="32px" /> : <HamburgerIcon width="32px" />}
              </IconButton>
              <Flex alignItems="center" style={{ gap: "10px" }}>
                <Text>{activeLink ? activeLink.label : "Overview"}</Text>
              </Flex>
            </MobLogoWrap>
            <Flex alignItems="center" height="100%">
              <AtomBox mr="12px" display={{ xs: "none", lg: "block" }}>
                <ZinuPrice showSkeleton={false} zinuPriceUsd={zinuPriceUsd} />
              </AtomBox>
              <Box mt="4px"></Box>
              {rightSide}
            </Flex>
          </NavContainer>

          <MainContainer className="dapp-main">
            <img
              src="/images/star-main.webp"
              alt=""
              style={{ position: "absolute", top: "0", left: "0", width: "100%" }}
            />
            {children}
            <img
              src="/images/gradient-main.webp"
              alt=""
              style={{ position: "absolute", bottom: "0", left: "0", width: "100%" }}
            />
          </MainContainer>
        </MainWrapper>
      </AtomBox>
    </MenuContext.Provider>
  );
};

export default Menu;
