import type { Signer } from '@ethersproject/abstract-signer'
import type { Provider } from '@ethersproject/providers'
import { provider } from 'utils/wagmi'
import { Contract } from '@ethersproject/contracts'
import { ZADI } from '@zinuswap/tokens'
import bscPoolsConfig from 'config/constants/pools/56'
import bscTestnetPoolsConfig from 'config/constants/pools/97'
import ethereumPoolsConfig from 'config/constants/pools/1'
import goerliTestnetPoolsConfig from 'config/constants/pools/5'

// Addresses
import {
  getMasterChefAddress,
  getZinuVaultAddress,
  getMulticallAddress,
  getZinuFlexibleSideVaultAddress,
  getBZinuFarmBoosterAddress,
  getBZinuFarmBoosterProxyFactoryAddress,
} from 'utils/addressHelpers'

// ABI
import bep20Abi from 'config/abi/erc20.json'
import erc721Abi from 'config/abi/erc721.json'
import lpTokenAbi from 'config/abi/lpToken.json'
import zinuAbi from 'config/abi/zinu.json'
import masterChef from 'config/abi/masterchef.json'
import sousChefV2 from 'config/abi/sousChefV2.json'
import zinuVaultV2Abi from 'config/abi/zinuVaultV2.json'
import zinuFlexibleSideVaultV2Abi from 'config/abi/zinuFlexibleSideVaultV2.json'
import chainlinkOracleAbi from 'config/abi/chainlinkOracle.json'
import MultiCallAbi from 'config/abi/Multicall.json'
import erc721CollectionAbi from 'config/abi/erc721collection.json'
import bZinuFarmBoosterAbi from 'config/abi/bZinuFarmBooster.json'
import bZinuFarmBoosterProxyFactoryAbi from 'config/abi/bZinuFarmBoosterProxyFactory.json'
import bZinuProxyAbi from 'config/abi/bZinuProxy.json'

// Types
import type {
  ChainlinkOracle,
  Erc20,
  Erc721,
  Zinu,
  Masterchef,
  SousChefV2,
  LpToken,
  Multicall,
  Erc721collection,
  ZinuVaultV2,
  ZinuFlexibleSideVaultV2,
  BZinuFarmBooster,
  BZinuFarmBoosterProxyFactory,
  BZinuProxy,
} from 'config/abi/types'
import { ChainId } from '@zinuswap/sdk'

const poolsConfig = [...bscPoolsConfig, ...bscTestnetPoolsConfig, ...ethereumPoolsConfig, ...goerliTestnetPoolsConfig] // TODO-SD

export const getContract = ({
  abi,
  address,
  chainId,
  signer,
}: {
  abi: any
  address: string
  chainId: ChainId
  signer?: Signer | Provider
}) => {
  const signerOrProvider = signer ?? provider({ chainId })
  return new Contract(address, abi, signerOrProvider)
}

export const getBep20Contract = (address: string, chainId: ChainId, signer?: Signer | Provider) => {
  return getContract({ abi: bep20Abi, address, signer, chainId }) as Erc20
}
export const getErc721Contract = (address: string, chainId: ChainId, signer?: Signer | Provider) => {
  return getContract({ abi: erc721Abi, address, signer, chainId }) as Erc721
}
export const getLpContract = (address: string, chainId?: number, signer?: Signer | Provider) => {
  return getContract({ abi: lpTokenAbi, address, signer, chainId }) as LpToken
}
export const getSouschefV2Contract = (id: number, chainId: ChainId, signer?: Signer | Provider) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  return getContract({ abi: sousChefV2, address: config.contractAddress, signer, chainId }) as SousChefV2
}

export const getZinuContract = (chainId: number, signer?: Signer | Provider) => {
  return getContract({
    abi: zinuAbi,
    address: chainId ? ZADI[chainId].address : ZADI[ChainId.BSC].address,
    signer,
    chainId
  }) as Zinu
}
export const getMasterchefContract = (chainId: number, signer?: Signer | Provider) => {
  return getContract({ abi: masterChef, address: getMasterChefAddress(chainId), signer, chainId }) as Masterchef
}

export const getZinuVaultV2Contract = (chainId: number, signer?: Signer | Provider) => {
  return getContract({ abi: zinuVaultV2Abi, address: getZinuVaultAddress(chainId), signer, chainId }) as ZinuVaultV2
}

export const getZinuFlexibleSideVaultV2Contract = (chainId: number, signer?: Signer | Provider) => {
  return getContract({
    abi: zinuFlexibleSideVaultV2Abi,
    address: getZinuFlexibleSideVaultAddress(chainId),
    signer,
    chainId
  }) as ZinuFlexibleSideVaultV2
}

export const getChainlinkOracleContract = (address: string, chainId: number, signer?: Signer | Provider) => {
  return getContract({ abi: chainlinkOracleAbi, address, signer, chainId }) as ChainlinkOracle
}
export const getMulticallContract = (chainId: ChainId) => {
  return getContract({ abi: MultiCallAbi, address: getMulticallAddress(chainId), chainId }) as Multicall
}

export const getErc721CollectionContract = (chainId: ChainId, signer?: Signer | Provider, address?: string) => {
  return getContract({ abi: erc721CollectionAbi, address, signer, chainId }) as Erc721collection
}

export const getBZinuFarmBoosterContract = (chainId: number, signer?: Signer | Provider) => {
  return getContract({ abi: bZinuFarmBoosterAbi, address: getBZinuFarmBoosterAddress(chainId), signer, chainId }) as BZinuFarmBooster
}

export const getBZinuFarmBoosterProxyFactoryContract = (chainId: number, signer?: Signer | Provider) => {
  return getContract({
    abi: bZinuFarmBoosterProxyFactoryAbi,
    address: getBZinuFarmBoosterProxyFactoryAddress(chainId),
    signer,
    chainId
  }) as BZinuFarmBoosterProxyFactory
}

export const getBZinuProxyContract = (proxyContractAddress: string, chainId: ChainId, signer?: Signer | Provider) => {
  return getContract({ abi: bZinuProxyAbi, address: proxyContractAddress, signer, chainId }) as BZinuProxy
}
