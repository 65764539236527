import { ChainId, ERC20Token } from '@zinuswap/sdk'

export const ZADI_BSC = new ERC20Token(
  ChainId.BSC,
  '0xfabc78709e5f99b50ecadf439621b33c0a05f879', // SOLVED-SD
  18,
  'ZADI',
  'ZADI',
  'https://zadiStaking.com/',
)


export const ZADI_BASE= new ERC20Token(ChainId.BASE,

  '0x4B60E77b2541f40448ADC99B61B1f8A1DeeE85c3',
  18,
  'ZADI',
  'ZADI',
  'https://zadiStaking.com/'
  )


export const ZADI_MAINNETZ = new ERC20Token(
  ChainId.MAINNETZ,
  '0xCDB448aEBE207aF00fFB9EAfd924134E0778de5c', // SOLVED-SD
  18,
  'ZADI',
  'ZADI',
  '#',
)

export const ZADI_MAINNETZ_TESTNET = new ERC20Token(
  ChainId.MAINNETZ_TESTNET,
  '0x4B60E77b2541f40448ADC99B61B1f8A1DeeE85c3', // SOLVED-SD
  18,
  'ZADI',
  'ZADI',
  '#',
)

export const ZADI_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0xb274ffDc019F9Fb51ec1B178c76b5cdA649DcCb4', // SOLVED-SD
  18,
  'ZADI',
  'ZADI',
  'https://zinustaking.com/',
)

export const ZADI_ETHEREUM = new ERC20Token(
  ChainId.ETHEREUM,
  '0x03f86c5ea2c3aa3e3662afc77438effb727734e6', // SOLVED-SD
  18,
  'ZADI',
  'ZADI',
  '#',
)

export const ZADI_GOERLI = new ERC20Token(
  ChainId.GOERLI,
  '0x79F089E60e62b5B59129b433AE7f37b40621BFcf', // SOLVED-SD
  18,
  'ZADI',
  'ZADI',
  'https://zinustaking.com/',
)

export const USDC_BSC = new ERC20Token(
  ChainId.BSC,
  '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d', // ORIGIN-SD
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0x64544969ed7EBf5f083679233325356EbE738930', // ORIGIN-SD
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', // ORIGIN-SD
  6,
  'USDC',
  'USD Coin',
)

export const USDC_GOERLI = new ERC20Token(
  ChainId.GOERLI,
  '0x07865c6E87B9F70255377e024ace6630C1Eaa37F', // ORIGIN-SD
  6,
  'tUSDC',
  'test USD Coin',
)


export const USDT_MAINNETZ = new ERC20Token(
  ChainId.MAINNETZ,
  '0x4B60E77b2541f40448ADC99B61B1f8A1DeeE85c3', // ORIGIN-SD
  18,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const USDT_MAINNETZ_TESTNET = new ERC20Token(
  ChainId.MAINNETZ_TESTNET,
  '0x9fa6182C041c52b714d8b402C4e358881a53067d', // ORIGIN-SD
  18,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const USDT_BSC = new ERC20Token(
  ChainId.BSC,
  '0x55d398326f99059fF775485246999027B3197955', // ORIGIN-SD
  18,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const USDT_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0xdAC17F958D2ee523a2206206994597C13D831ec7', // ORIGIN-SD
  6,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)


export const USDT_BASE = new ERC20Token(
  ChainId.BASE,
  '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913', // ORIGIN-SD
  6,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const BUSD_BSC = new ERC20Token(
  ChainId.BSC,
  '0x55d398326f99059fF775485246999027B3197955', // ORIGIN-SD
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814', // ORIGIN-SD
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0x4Fabb145d64652a948d72533023f6E7A623C7C53', // ORIGIN-SD
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_MAINNETZ = new ERC20Token(
  ChainId.MAINNETZ,
  '0x4Fabb145d64652a948d72533023f6E7A623C7C53', // ORIGIN-SD
  18,
  'MAINNETZ',
  'BUSD',
  'https://www.paxos.com/busd/',
)

export const BUSD_MAINNETZ_TESTNET = new ERC20Token(
  ChainId.MAINNETZ_TESTNET,
  '0x9fa6182C041c52b714d8b402C4e358881a53067d', // ORIGIN-SD
  18,
  'MAINNETZ',
  'BUSD',
  'https://www.paxos.com/busd/',
)

export const BUSD_GOERLI = new ERC20Token(
  ChainId.GOERLI,
  '0xb809b9B2dc5e93CB863176Ea2D565425B03c0540', // ORIGIN-SD
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD: Record<ChainId, ERC20Token> = {

  [ChainId.BASE]: USDT_BASE,
  [ChainId.MAINNETZ]:BUSD_MAINNETZ,
  [ChainId.MAINNETZ_TESTNET]:BUSD_MAINNETZ_TESTNET,
  [ChainId.ETHEREUM]: BUSD_ETH,
  [ChainId.GOERLI]: BUSD_GOERLI,
  [ChainId.BSC]: BUSD_BSC,
  [ChainId.BSC_TESTNET]: BUSD_TESTNET,
}

export const ZADI = {
  [ChainId.BASE]: ZADI_BASE,
  [ChainId.MAINNETZ_TESTNET]:ZADI_MAINNETZ_TESTNET,
  [ChainId.MAINNETZ]: ZADI_MAINNETZ,
  [ChainId.BSC]: ZADI_BSC,
  [ChainId.BSC_TESTNET]: ZADI_TESTNET,
  [ChainId.ETHEREUM]: ZADI_ETHEREUM,
  [ChainId.GOERLI]: ZADI_GOERLI,
}

export const USDC = {

   [ChainId.BASE]: USDT_BASE,
  [ChainId.BSC]: USDC_BSC,
  [ChainId.BSC_TESTNET]: USDC_TESTNET,
  [ChainId.ETHEREUM]: USDC_ETH,
  [ChainId.GOERLI]: USDC_GOERLI,
}

export const USDT = {
  [ChainId.BASE]: USDT_BASE,
  [ChainId.BSC]: USDT_BSC,
  [ChainId.ETHEREUM]: USDT_ETH,
}

export const WBTC_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
  8,
  'WBTC',
  'Wrapped BTC',
)

export const STABLE_TOKEN = {
  [ChainId.MAINNETZ_TESTNET]:USDT_MAINNETZ_TESTNET,
  [ChainId.BASE]: USDT_BASE,
  [ChainId.MAINNETZ]:USDT_MAINNETZ,
  [ChainId.ETHEREUM]: USDC_ETH,
  [ChainId.GOERLI]: USDC_GOERLI,
  [ChainId.BSC]: BUSD_BSC,
  [ChainId.BSC_TESTNET]: BUSD_TESTNET,
}