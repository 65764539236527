import { formatUnits } from '@ethersproject/units';
import { MultiCallV2 } from '@zinuswap/multicall'
import { ChainId } from '@zinuswap/sdk'
import { ZINU_DECIMALS } from 'config/constants';
import { masterChefAddresses } from './const'
import { farmV2FetchFarms, FetchFarmsParams, fetchMasterChefV2Data } from './fetchFarms'

const supportedChainId = [ChainId.BASE, ChainId.MAINNETZ, ChainId.GOERLI, ChainId.BSC, ChainId.BSC_TESTNET, ChainId.ETHEREUM]

export const bZinuSupportedChainId = []

export function createFarmFetcher(multicallv2: MultiCallV2) {
  const fetchFarms = async (
    params: {
      chainId: ChainId
    } & Pick<FetchFarmsParams, 'chainId' | 'farms'>,
  ) => {
    const { farms, chainId } = params
    const masterChefAddress = masterChefAddresses[chainId]
    const { poolLength, totalRegularAllocPoint, totalSpecialAllocPoint, zinuPerBlock } = await fetchMasterChefV2Data({
      chainId,
      multicallv2,
      masterChefAddress,
    })
    
    const regularZinuPerBlock = formatUnits(zinuPerBlock, ZINU_DECIMALS)
    const farmsWithPrice = await farmV2FetchFarms({
      multicallv2,
      masterChefAddress,
      chainId,
      farms: farms.filter((f) => !f.pid || poolLength.gt(f.pid)),
      totalRegularAllocPoint,
      totalSpecialAllocPoint,
    })

    return {
      farmsWithPrice,
      poolLength: poolLength.toNumber(),
      regularZinuPerBlock: +regularZinuPerBlock,
    }
  }
  return {
    fetchFarms,
    isChainSupported: (chainId: number) => supportedChainId.includes(chainId),
    supportedChainId,
    isTestnet: (chainId: number) => ![ChainId.BSC, ChainId.ETHEREUM].includes(chainId),
  }
}

export * from './apr'
export * from './farmsPriceHelpers'
export * from './types'
